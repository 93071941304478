import UploadsHeader from '../components/UploadsHeader';
import UploadsTable from '../components/UploadsTable';

export default function UploadsView() {
	return (
		<>
			<UploadsHeader />
			<div className="mt-10">
				<UploadsTable />
			</div>
		</>
	);
}
