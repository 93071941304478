import { getButtonStyleAttributes } from 'components/MarkmiButton/Button';
import Title from 'components/Title/Title';
import { cn } from 'crunch-components';

const MarkdownTransferRadios = ({
	options,
	value,
	onChange,
	disabled,
	className = '',
}) => {
	const optionsExtended = [];
	options.forEach((option) =>
		optionsExtended.push({ value: option, isComingSoon: false })
	);

	const createLabelInner = (
		isActive,
		optionValue,
		htmlFor,
		labelIsDisabled
	) => {
		return (
			<label
				className={cn(
					isActive
						? 'border border-green-500 text-green-500 bg-green-100 cursor-pointer'
						: 'text-gray-500 bg-gray-200 border border-gray-200  hover:border hover:border-gray-300 cursor-pointer',
					labelIsDisabled ? 'opacity-40' : 'opacity-100',
					'select-none py-1.5 px-3 transition-all capitalize rounded-lg block'
				)}
				htmlFor={htmlFor}
			>
				{optionValue}
			</label>
		);
	};

	return (
		
		<div className='mb-4'>
			<Title size="h4-like" font="sans" color="neutral-darker" as="h3">
				What do you want to transfer?
			</Title>
			<div className={cn('flex gap-2 mt-4', className)}>
				{optionsExtended.map((optionObject, index) => (
					<div key={optionObject.value}>
						<input
							disabled={disabled}
							className="hidden"
							type="radio"
							value={optionObject.value}
							name="units"
							id={`unit-${index}`}
							checked={value === optionObject.value}
							onChange={(e) => onChange(e.currentTarget.value)}
							/>
						{createLabelInner(
							value === optionObject.value,
							optionObject.value,
							`unit-${index}`,
							disabled
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default MarkdownTransferRadios;
