import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Minus = ({ className }) => (_jsx("svg", { className: className, xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", viewBox: "0 0 256 256", children: _jsx("path", { fill: "currentColor", d: "M224 128a8 8 0 0 1-8 8H40a8 8 0 0 1 0-16h176a8 8 0 0 1 8 8Z", strokeWidth: 4 }) }));
Minus.propTypes = {
    className: PropTypes.string,
};
Minus.defaultProps = {
    className: '',
};
export default Minus;
