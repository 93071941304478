import { Button, PlusIcon, Text } from 'crunch-components';
import TableBusinessRules from '../Components/OverviewTable';
import { useCreateModalBusinessRule } from './components/BusinessRuleCreateEditModalBase';

const BusinessRulesOverview = () => {
	const { open } = useCreateModalBusinessRule();
	const jsx = (
		<>
			<div className="flex items-center justify-between gap-4">
				<Text type="secondary" className="flex-shrink py-10">
					Business rules allow you to make sure the recommended
					markdowns are in line with the specific rules and
					constraints of your organization.
				</Text>
				<Button
					size="page-cta"
					onClick={open}
					className="flex h-[30px] w-[168px] max-w-[168px] flex-grow items-center justify-center gap-2 font-normal"
				>
					<PlusIcon className="h-2.5 w-auto" />
					<span className="whitespace-nowrap">Add business rule</span>
				</Button>
			</div>
			<TableBusinessRules />
		</>
	);

	return jsx;
};

export default BusinessRulesOverview;
