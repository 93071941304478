import { Checkbox } from 'crunch-components';
import { ComponentProps } from 'react';

type CheckboxProps = ComponentProps<typeof Checkbox>;
const variantsClassNames: CheckboxProps['variantStyling'] = {
	original: {
		checkboxClassName: {
			checked: {
				enabled:
					'border border-mi-neutral/50 focus:border-mi-velvet-lilac bg-mi-neutral-lightest hover:border-mi-velvet-lilac hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac',
				disabled:
					'pointer-events-none border border-mi-neutral/50 bg-mi-neutral-lightest opacity-70 mix-blend-multiply',
			},
			mixed: {
				enabled:
					'border border-mi-neutral/50 focus:border-mi-velvet-lilac bg-mi-neutral-lightest hover:border-mi-velvet-lilac hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac',
				disabled:
					'pointer-events-none border border-mi-neutral/50 bg-mi-neutral-lightest opacity-70 mix-blend-multiply',
			},
			unchecked: {
				enabled:
					'border border-mi-neutral/50 focus:border-mi-velvet-lilac bg-mi-neutral-lightest hover:border-mi-velvet-lilac hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac',
				disabled:
					'pointer-events-none border border-mi-neutral/50 bg-mi-neutral-lightest opacity-70 mix-blend-multiply',
			},
		},
		iconClassName: {
			checked: {
				enabled: 'text-mi-velvet-lilac',
				disabled: 'text-mi-neutral',
			},
			mixed: {
				enabled: 'text-mi-velvet-lilac',
				disabled: 'text-mi-neutral',
			},
			unchecked: {
				enabled: 'text-mi-velvet-lilac',
				disabled: 'text-mi-neutral',
			},
		},
	},
	filled: {
		checkboxClassName: {
			checked: {
				enabled:
					'bg-mi-velvet-lilac hover:bg-mi-velvet-lilac/90 transition-all hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac',
				disabled:
					'pointer-events-none bg-mi-neutral opacity-50 mix-blend-multiply',
			},
			mixed: {
				enabled:
					'bg-mi-velvet-lilac hover:bg-mi-velvet-lilac/90 transition-all hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac',

				disabled:
					'pointer-events-none bg-mi-neutral/80 opacity-50 mix-blend-multiply ',
			},
			unchecked: {
				enabled:
					'bg-mi-velvet-lilac-light/60 hover:border-mi-velvet-lilac/30  hover:bg-mi-velvet-lilac-light/40  transition-all border border-mi-velvet-lilac/20',
				disabled:
					'pointer-events-none bg-mi-neutral/80 opacity-50 mix-blend-multiply ',
			},
		},
		iconClassName: {
			checked: {
				enabled: 'text-mi-neutral-lightest',
				disabled: 'text-mi-neutral-darker',
			},
			mixed: {
				enabled: 'text-mi-neutral-lightest',
				disabled: 'text-mi-neutral-darker',
			},
			unchecked: {
				enabled: '',
				disabled: '',
			},
		},
	},
	outline: {
		checkboxClassName: {
			checked: {
				enabled:
					'bg-transparent hover:ring border transition-all hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac border-mi-velvet-lilac-light',
				disabled:
					'pointer-events-none bg-mi-neutral/20 border-mi-neutral border opacity-50 mix-blend-multiply',
			},
			mixed: {
				enabled:
					'bg-transparent hover:ring border transition-all hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac border-mi-velvet-lilac-light',
				disabled:
					'pointer-events-none bg-mi-neutral/20 border-mi-neutral border opacity-50 mix-blend-multiply',
			},
			unchecked: {
				enabled:
					'bg-transparent hover:ring border transition-all hover:ring-2 hover:ring-opacity-15 ring-mi-velvet-lilac border-mi-velvet-lilac-light',
				disabled:
					'pointer-events-none bg-mi-neutral/20 border-mi-neutral border opacity-50 mix-blend-multiply',
			},
		},
		iconClassName: {
			checked: {
				enabled: 'text-mi-velvet-lilac',
				disabled: 'text-mi-neutral-dark',
			},
			mixed: {
				enabled: 'text-mi-velvet-lilac',
				disabled: 'text-mi-neutral-dark',
			},
			unchecked: {
				enabled: '',
				disabled: '',
			},
		},
	},
};

export default function StratosCheckbox(props: CheckboxProps) {
	return <Checkbox {...props} variantStyling={variantsClassNames} />;
}
