import { cn } from 'crunch-components';
import { ReactNode } from 'react';

type TitleProps = {
	font: 'serif' | 'sans';
	size: 'h1-like' | 'h2-like' | 'h3-like' | 'h4-like' | 'h5-like' | 'h6-like';
	color: 'forest' | 'neutral-darker' | 'muted';
	/* the HTML element your title will be <h1> <h2>... */
	as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	className?: string;
	children?: ReactNode;
};

const fontCn: Record<TitleProps['font'], string> = {
	sans: 'font-sans',
	serif: 'font-gazpacho',
};

const sizeCn: Record<TitleProps['font'], Record<TitleProps['size'], string>> = {
	sans: {
		'h1-like': 'text-5xl font-bold',
		'h2-like': 'text-3xl font-bold',
		'h3-like': 'text-xl font-bold',
		'h4-like': 'text-lg font-bold',
		'h5-like': 'text-md font-bold',
		'h6-like': 'text-sm font-bold',
	},
	serif: {
		'h1-like': 'text-5xl',
		'h2-like': 'text-3xl',
		'h3-like': 'text-xl',
		'h4-like': 'text-lg',
		'h5-like': 'text-md',
		'h6-like': 'text-sm',
	},
};
const colorCn: Record<TitleProps['color'], string> = {
	forest: 'text-mi-night-forest',
	'neutral-darker': 'text-mi-neutral-darker',
	muted: 'text-mi-neutral-dark',
};

export default function Title(props: TitleProps) {
	const H = props.as;
	return (
		<H
			className={cn(
				sizeCn[props.font][props.size],
				fontCn[props.font],
				colorCn[props.color],
				'leading-[120%]',
				props.className,
			)}
		>
			{props.children}
		</H>
	);
}
