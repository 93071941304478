import Title from 'components/Title/Title';
import { Text, Tooltip } from 'crunch-components';

const ActiveFilterPill = ({ title }) => {
	return (
		<Text
			size="text-xs"
			className="text-gray-500 border border-gray-500 rounded-full px-2.5 py-0.5 block"
		>
			{title}
		</Text>
	);
};

// TODO: title tooltip is broken?
const MarkdownTransferActiveFilters = ({ filters }) => {
	const filtersArray = Array.isArray(filters) ? filters : [];
	return (
		<section className="mb-8">
			<header className="mb-4">
				<Tooltip content="The filters you have selected for this scenario">
					<Title size="h4-like" font="sans" color="neutral-darker" as="h3" className='flex gap-2 items-center'>
						Active filters
						<Text
							className="bg-mi-neutral-dark/80 text-white w-5 h-5 rounded-full flex justify-center items-center font-bold"
							size="text-xs"
						>
							{filtersArray.length}
						</Text>
					</Title>
				</Tooltip>
			</header>
			<div className="flex items-baseline gap-2 mb-4">
				{filtersArray.length > 0 ? (
					<ul className="flex justify-center gap-2">
						{filtersArray.map((filter) => {
							return (
								<li key={filter}>
									<ActiveFilterPill title={filter} />
								</li>
							);
						})}
					</ul>
				) : (
					<Text type="secondary">No active filters...</Text>
				)}
			</div>
		</section>
	);
};

export default MarkdownTransferActiveFilters;
