import { cn } from 'crunch-components';
import { Prettify } from 'pages/strategy/types/helpers';
import {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	forwardRef,
	ForwardRefExoticComponent,
	PropsWithoutRef,
	RefAttributes,
} from 'react';

type HTMLButtonProps = DetailedHTMLProps<
	ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

type Variant = 'solid' | 'ghostFill' | 'soft';
type Color =
	| 'velvet-lilac'
	| 'forest'
	| 'lime-mist'
	| 'neutral'
	| 'icy-breeze'
	| 'danger';
type Size = 'sm' | 'md' | 'xl';
type PaddingMode = 'square' | `rectangle`;

/*
	returns the exact classnames like our Buttons
	variant + color + baseclassnames
	Requires "aria-disabled" to be set explicity to true/false to work
*/

export function getButtonStyleAttributes(
	options: Prettify<
		Pick<
			ButtonProps,
			| 'variant'
			| 'color'
			| 'paddingMode'
			| 'disabled'
			| 'className'
			| 'size'
			| 'unstyled'
			| 'disabledStyle'
		>
	>,
): Pick<
	ButtonHTMLAttributes<HTMLButtonElement>,
	'disabled' | 'aria-disabled' | 'className'
> {
	const _options: typeof options = {
		disabled: options.disabled ?? false,
		paddingMode: options.paddingMode ?? 'rectangle',
		unstyled: options.unstyled ?? false,
		variant: options.variant ?? 'solid',
		color: options.color ?? 'velvet-lilac',
		size: options.size ?? 'md',
		className: options.className,
		disabledStyle: options.disabledStyle,
	};
	return {
		disabled: _options.disabled,
		'aria-disabled': _options.disabled,
		className: cn(
			'rounded-md transition-all leading-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple  ring-ca-purple flex items-center gap-2 justify-center',
			!_options.unstyled &&
				'leading-none border border-transparent whitespace-nowrap ',
			!_options.unstyled &&
				buttonClassNames[_options.variant!][_options.color!],
			!_options.unstyled && sizeClassNames[_options.size!],
			!_options.unstyled &&
				paddingClassNames[_options.size!][_options.paddingMode!],
			_options.disabled &&
				!_options.disabledStyle &&
				'cursor-not-allowed opacity-30',
			_options.disabled &&
				_options.disabledStyle &&
				buttonClassNames[_options.disabledStyle][_options.color!],
			_options.className,
		),
	};
}

const sizeClassNames: Record<Size, string> = {
	sm: 'rounded-md text-xs',
	md: 'rounded-lg text-sm',
	xl: 'rounded-full text-base',
};

const paddingClassNames: Record<Size, Record<PaddingMode, string>> = {
	sm: {
		square: 'p-3',
		rectangle: 'py-2.5 px-3',
	},
	md: {
		square: 'p-3',
		rectangle: 'py-3 px-5',
	},
	xl: {
		square: 'p-4',
		rectangle: 'py-6 px-6',
	},
};

const buttonClassNames = {
	solid: {
		forest: `text-mi-lime-mist bg-mi-night-forest  aria-[disabled=false]:hover:bg-[#377264] focus:text-white  aria-[disabled=false]:hover:text-white active:brightness-95`,
		'velvet-lilac':
			'text-mi-velvet-lilac-lightest bg-mi-velvet-lilac focus:brightness-110  aria-[disabled=false]:hover:brightness-110',
		'lime-mist':
			'text-mi-lime-mist-light bg-mi-lime-mist-dark focus:brightness-110  aria-[disabled=false]:hover:bg-mi-lime-mist', //UGLY
		'icy-breeze': '',
		neutral: '',
		danger: '',
	},
	soft: {
		forest: '',
		'velvet-lilac':
			'text-mi-velvet-lilac bg-mi-velvet-lilac-lighter    aria-[disabled=false]:hover:brightness-[97%] focus:border focus:border-mi-velvet-lilac-light',
		'lime-mist':
			'text-mi-lime-mist-dark bg-mi-lime-mist-light focus:brightness-110  aria-[disabled=false]:hover:bg-mi-lime-mist',
		'icy-breeze':
			'text-mi-sec-icy-breeze-dark bg-mi-sec-icy-breeze-light    aria-[disabled=false]:hover:brightness-[97%]',
		neutral:
			'bg-mi-neutral-light mix-blend-multiply text-mi-neutral-dark   aria-[disabled=false]:hover:brightness-[97%]',
		danger: 'text-red-500 bg-red-200/25    aria-[disabled=false]:hover:bg-red-200/70',
	},
	ghostFill: {
		forest: 'text-mi-day-forest bg-mi-neutral-light aria-[disabled=false]:hover:bg-mi-day-forest/10 border-mi-day-forest',
		'velvet-lilac':
			'text-mi-velvet-lilac   aria-[disabled=false]:hover:bg-mi-velvet-lilac-lighter',
		'icy-breeze': '',
		'lime-mist': '',
		neutral:
			'text-mi-neutral-dark  aria-[disabled=false]:hover:bg-mi-neutral-light mix-blend-multiply',
		danger: 'text-red-500   aria-[disabled=false]:hover:bg-red-100/50',
	},
} as const; // satisfies Record<Variant, Record<Color, string>>;

export interface ButtonProps extends HTMLButtonProps {
	color?: Color;
	/* `bare` will   */
	variant?: Variant;
	/* Will ignore color and variant props*/
	unstyled?: boolean;
	size?: Size;
	/* Tip: if you want a square button, you also need to change the line-height to none */
	paddingMode?: PaddingMode;
	disabledStyle?: Variant;
}

// Difference between label and button
// Label has less height, text is all caps and a little fatter. Icon should be on left side maybe.

/* ! Icons always to the left of the text. Only correct place to put them. */
/* ! You are responsible for loading spinners etc. */

const Button: ForwardRefExoticComponent<
	PropsWithoutRef<ButtonProps> & RefAttributes<HTMLButtonElement>
> = forwardRef(
	(
		{
			variant,
			color,
			type,
			paddingMode,
			size,
			unstyled,
			disabled,
			disabledStyle,
			form,
			className,
			children,
			onClick,
			...rest
		}: ButtonProps,
		ref,
	) => {
		return (
			<button
				ref={ref}
				type={type}
				onClick={onClick}
				form={form}
				{...getButtonStyleAttributes({
					variant,
					color,
					paddingMode,
					size,
					disabled,
					className,
					unstyled,
					disabledStyle,
				})}
				{...rest}
			>
				{children}
			</button>
		);
	},
);

export default Button;
