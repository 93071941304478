import Button from 'components/MarkmiButton/Button';
import { cn } from 'crunch-components';
import React from 'react';

//  __________________________________________________________________________________________________
//
//  1 <StepsSideBar>																1 <StepsSideBar>
// ___________________________________________________________________________________________________

/**
 * ------------------------------------------------------------------
 * 1.1 | TYPES | <STEPS SIDEBAR> (default export)
 * ------------------------------------------------------------------
 */

export type StepsSidebarProps = {
	Badge: React.ReactElement<React.HtmlHTMLAttributes<HTMLElement>>;
	title: string;
	steps: React.ReactElement<StepButtonProps>[];
	className?: string;
};

/**
 * ------------------------------------------------------------------
 * 1.2 | COMPONENT | <STEPS SIDEBAR> (default export)
 * ------------------------------------------------------------------
 */

const StepsSidebar = (props: StepsSidebarProps) => {
	const BadgeElement = props.Badge.type;
	return (
		<>
			<div className="flex w-48 flex-col justify-center gap-8 rounded-md bg-mi-neutral/15 p-4">
				<div className="flex flex-col items-start gap-1">
					<BadgeElement
						{...props.Badge.props}
						key={props.Badge.key}
					/>
					<span className="block text-sm font-medium text-mi-neutral-dark">
						{props.title}
					</span>
				</div>
				<div className="flex flex-col gap-4">{props.steps}</div>
			</div>
		</>
	);
};

export default StepsSidebar;

//  __________________________________________________________________________________________________
//
//  2 <Step>																				2 <Step>
// ___________________________________________________________________________________________________

/**
 * ------------------------------------------------------------------
 * 2.1 | TYPES | <STEP>
 * ------------------------------------------------------------------
 */

export type StepButtonProps = {
	title: string;
	state: 'clickable' | 'current' | 'disabled' | 'checkmark';
	onClick: () => void;
};

/**
 * ------------------------------------------------------------------
 * 2.1 | COMPONENT | <STEP>
 * ------------------------------------------------------------------
 */

export const StepButton: React.FC<StepButtonProps> = (props) => {
	return (
		<Button
			variant="soft"
			color="neutral"
			size="sm"
			className={cn(
				'grid grid-cols-[min-content_1fr] gap-2 whitespace-normal mix-blend-multiply',
				props.state !== 'disabled'
					? 'rounded-md transition-colors hover:bg-ca-silver'
					: 'cursor-not-allowed',
			)}
			onClick={props.onClick}
		>
			<div className="grid h-full place-items-center px-2">
				<div
					className={cn(
						'h-2 w-2 rounded-full',
						props.state === 'clickable' && 'bg-ca-purple',
						props.state === 'current' &&
							'col-start-1 row-start-1 bg-ca-purple',
						props.state === 'disabled' && 'bg-ca-gray-400',
					)}
				/>
				{props.state === 'current' && (
					<div className="col-start-1 row-start-1 h-2 w-2 animate-ping rounded-full bg-ca-purple" />
				)}
			</div>
			<span
				className={cn(
					'text-left text-xs',
					props.state === 'clickable' && 'text-mi-neutral-dark',
					props.state === 'current' && 'text-mi-velvet-lilac',
					props.state === 'disabled' && 'text-mi-neutral',
				)}
			>
				{props.title}
			</span>
		</Button>
	);
};
