import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Logo = ({ className }) => (_jsxs("svg", { id: "Layer_1", className: className, viewBox: "0 0 277.00001 277.00001", version: "1.1", width: "277", height: "277", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("defs", { id: "defs1", children: [_jsx("linearGradient", { id: "swatch6", children: _jsx("stop", { style: { 'stop-color': '#0f2929', 'stop-opacity': 1 }, offset: "0", id: "stop6" }) }), _jsx("linearGradient", { xlinkHref: "#swatch6", id: "linearGradient6", x1: "0", y1: "105.09", x2: "210.0925", y2: "105.09", gradientUnits: "userSpaceOnUse", gradientTransform: "matrix(0.84248613,0,0,0.84248613,50.000001,50.000001)" })] }), _jsx("g", { id: "layer1", style: { display: 'inline', fill: '#ebe1ff' }, children: _jsx("path", { style: { fill: '#ebe1ff', fillOpacity: 1, strokeWidth: 1.03553 }, id: "rect6", width: "277", height: "277", x: "-34", y: "-34", d: "M 10.677547,-34 H 198.32245 C 223.07381,-34 243,-14.073814 243,10.677547 V 198.32245 C 243,223.07381 223.07381,243 198.32245,243 H 10.677547 C -14.073814,243 -34,223.07381 -34,198.32245 V 10.677547 C -34,-14.073814 -14.073814,-34 10.677547,-34 Z", ry: "44.677547", transform: "translate(34,34)" }) }), _jsx("g", { id: "layer2", children: _jsx("path", { d: "M 227,93.564956 C 202.93862,93.564956 183.43507,74.061403 183.43507,50 H 83.987998 c -1.51648,0 -2.75493,1.230031 -2.75493,2.754931 V 101.07152 L 122.16106,71.255924 c 1.53332,-1.120507 3.34466,0.994134 1.99669,2.333687 L 50.802469,147.05441 c -1.069958,1.07838 -1.069958,2.8139 0,3.89228 l 75.175031,75.31826 c 1.0784,1.07837 2.81392,1.07837 3.8923,0 l 73.80178,-73.70069 c 1.34798,-1.33954 3.4542,0.46337 2.33369,1.99671 l -30.08518,41.4166 h 48.31658 c 1.51647,0 2.75492,-1.23005 2.75492,-2.75493 V 93.556526 Z m -42.32649,18.062914 c -5.5604,7.16955 -16.26839,7.16955 -21.83724,0 -3.79119,-4.88643 -3.79119,-11.887484 0,-16.773914 5.5604,-7.16956 16.26841,-7.16956 21.83724,0 3.7912,4.88643 3.7912,11.887484 0,16.773914 z", id: "path1", style: {
                    fill: 'url(#linearGradient6)',
                    fillRule: 'evenodd',
                    strokeWidth: 0.842486,
                } }) })] }));
Logo.propTypes = {
    className: PropTypes.string,
};
Logo.defaultProps = {
    className: '',
};
export default Logo;
