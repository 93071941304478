import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import cn from '../../utils/cn';
import CheckmarkIcon from '../Icons/Checkmark';
import Minus from '../Icons/Minus';
import Text from '../Text/Text';
// add styling variant: outline & filled
// add third state - unchecked, mixed, checked => will this mess anything up? yes, onChange(bool) changes to onChange(checkboxValue)
export var CheckboxValue;
(function (CheckboxValue) {
    CheckboxValue["unchecked"] = "unchecked";
    CheckboxValue["mixed"] = "mixed";
    CheckboxValue["checked"] = "checked";
})(CheckboxValue || (CheckboxValue = {}));
/**
 * Ambiguous value (=`bool` or `CheckboxValue`) to `CheckboxValue`
 */
const ambiguousToCheckboxValue = (from) => {
    if (typeof from === 'boolean') {
        return from ? CheckboxValue.checked : CheckboxValue.unchecked;
    }
    return from;
};
const variantStyling = {
    original: {
        checkboxClassName: {
            checked: {
                enabled: 'border border-ca-silver focus:border-ca-purple bg-white',
                disabled: 'pointer-events-none',
            },
            mixed: {
                enabled: 'border border-ca-silver focus:border-ca-purple bg-white',
                disabled: 'pointer-events-none',
            },
            unchecked: {
                enabled: 'border border-ca-silver focus:border-ca-purple bg-white',
                disabled: 'pointer-events-none',
            },
        },
        iconClassName: {
            checked: {
                enabled: 'text-ca-purple',
                disabled: 'text-ca-gray',
            },
            mixed: {
                enabled: 'text-ca-purple',
                disabled: 'text-ca-gray',
            },
            unchecked: {
                enabled: 'text-ca-purple',
                disabled: 'text-ca-gray',
            },
        },
    },
    filled: {
        checkboxClassName: {
            checked: {
                enabled: 'bg-ca-purple hover:bg-checkbox-purple-hover transition-all hover:shadow-checkbox-hover shadow-checkbox-hover-off',
                disabled: 'text-ca-gray',
            },
            mixed: {
                enabled: 'bg-checkbox-purple-bg hover:bg-checkbox-purple-bg-hover border border-ca-purple hover:border-checkbox-purple-hover  transition-all hover:shadow-checkbox-hover shadow-checkbox-hover-off ',
                disabled: 'text-ca-gray',
            },
            unchecked: {
                enabled: 'bg-checkbox-purple-bg hover:bg-checkbox-purple-bg-hover border border-ca-purple hover:border-checkbox-purple-hover transition-all hover:shadow-checkbox-hover shadow-checkbox-hover-off ',
                disabled: 'text-ca-gray',
            },
        },
        iconClassName: {
            checked: {
                enabled: 'text-white',
                disabled: 'text-ca-gray',
            },
            mixed: {
                enabled: 'text-ca-purple',
                disabled: 'text-ca-gray',
            },
            unchecked: {
                enabled: '',
                disabled: 'text-ca-gray',
            },
        },
    },
    outline: {
        checkboxClassName: {
            checked: {
                enabled: 'bg-transparent border-ca-purple border transition-all hover:shadow-checkbox-hover',
                disabled: 'pointer-events-none',
            },
            mixed: {
                enabled: 'bg-transparent border-ca-purple border transition-all hover:shadow-checkbox-hover',
                disabled: 'pointer-events-none',
            },
            unchecked: {
                enabled: 'bg-transparent border-ca-purple border transition-all hover:shadow-checkbox-hover',
                disabled: 'pointer-events-none',
            },
        },
        iconClassName: {
            checked: {
                enabled: 'text-ca-purple',
                disabled: 'text-ca-gray',
            },
            mixed: {
                enabled: 'text-ca-purple',
                disabled: 'text-ca-gray',
            },
            unchecked: {
                enabled: 'text-ca-purple',
                disabled: 'text-ca-gray',
            },
        },
    },
};
const Checkbox = (props) => {
    const [hitAnimation, setHitAnimation] = useState(false);
    const [mounted, setMounted] = useState(false);
    const _variantStyling = props.variantStyling ?? variantStyling;
    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            return () => { };
        }
        setHitAnimation(true);
        const hitAnimationDurationMs = 250;
        const timer = setTimeout(() => {
            setHitAnimation(false);
        }, hitAnimationDurationMs);
        return () => clearTimeout(timer);
    }, [props.checked]);
    const safeVariant = props.variant ?? 'original';
    const transformedChecked = typeof props.checked === 'boolean' || typeof props.checked === 'undefined'
        ? ambiguousToCheckboxValue(props.checked)
        : props.checked;
    return (_jsxs("button", { disabled: props.disabled, type: "button", tabIndex: -1, onClick: props.overrideOnClick ??
            (() => {
                if (props.disabled || props.onChange === undefined) {
                    return;
                }
                if (transformedChecked === CheckboxValue.unchecked ||
                    transformedChecked === CheckboxValue.mixed) {
                    props.onChange(true);
                    return;
                }
                props.onChange(false);
            }), className: cn('flex items-center transition-colors leading-none', props.disabled && 'cursor-not-allowed', props.swapPosition && 'justify-between'), children: [_jsxs("span", { tabIndex: props.disabled ? -1 : 0, role: "button", className: cn('focus:ring-ca-purple focus:ring-4 focus:ring-opacity-10 active:ring-ca-purple active:ring-8 active:ring-opacity-20 ring-opacity-0 ring-0 transition-all rounded-[5px] inline-flex justify-center items-center shadow-md ', _variantStyling[safeVariant].checkboxClassName[ambiguousToCheckboxValue(props.checked)][props.disabled ? 'disabled' : 'enabled'], props.swapPosition && 'order-2 ml-10', hitAnimation && 'animate-hit'), style: { minHeight: '18px', minWidth: '18px' }, children: [transformedChecked === CheckboxValue.checked && (
                    // @ts-ignoreignore
                    _jsx(CheckmarkIcon, { className: cn('h-3', _variantStyling[safeVariant].iconClassName[CheckboxValue.checked][props.disabled ? 'disabled' : 'enabled']) })), transformedChecked === CheckboxValue.mixed && (_jsx(Minus, { className: cn('w-3', _variantStyling[safeVariant].iconClassName[CheckboxValue.mixed][props.disabled ? 'disabled' : 'enabled']) }))] }), props.label && (
            // @ts-ignoreignore
            _jsx(Text, { className: cn('text-left ml-2', props.swapPosition && 'order-1'), type: transformedChecked ? 'primary' : 'secondary', children: props.label ?? '' }))] }));
};
export default Checkbox;
