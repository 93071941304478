import React, { FunctionComponent, useRef, useState } from 'react';

import { ReactNodeLike } from 'prop-types';
import { useQueryClient } from 'react-query';

import Button from 'components/MarkmiButton/Button';
import filterMapToSearchParams from 'components/Filter/filterUtils';
import { FilterMap } from 'components/Filter/FullFilterMenu.types';

import Title from 'components/Title/Title';
import {
	InfoIcon,
	LoadingSpinner,
	Modal,
	Text,
	TextInput,
	Tooltip,
	useModal,
	useToast,
} from 'crunch-components';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useChannelStore from 'hooks/channels/useChannelStore';
import useMarkdownTransferMutation from 'hooks/mutations/useMarkdownTransferMutation';
import { useMarkdownBucketsQuery } from 'hooks/queries/useMarkdownBucketsQuery';
import useStrategyStore from 'hooks/useStrategyStore';
import constructChannelQueryKey from 'utils/channelUtils';
import { GET_NUMBER_TRANSFERS_BUSINESS_RULES } from '../../../api/recommendations';
import {
	EElementState,
	EUnit,
	MarkdownBucketDataType,
} from '../types/MarkdownTransferTypes';
import MarkdownTransferActiveFilters from './MarkdownTransferActiveFilters';
import MarkdownTransferBrace from './MarkdownTransferBrace';
import MarkdownTransferBucketsDropdown from './MarkdownTransferBucketsDropdown';
import MarkdownTransferCard from './MarkdownTransferCard';
import MarkdownTransferPhrase from './MarkdownTransferPhrase';
import MarkdownTransferRadios from './MarkdownTransferRadios';
import MarkdownTransferSelection from './MarkdownTransferSelection';

interface Props {
	cumulRef: React.RefObject<any>;
	activeFilters: FilterMap;
	transferMarkdownFn: ReturnType<
		typeof useMarkdownTransferMutation
	>['mutate'];
}

enum EMarkdownTransferFormState {
	promptUnit = 'promptUnit',
	promptFromBucket = 'promptFromBucket',
	promptAmount = 'promptAmount',
	promptToBucket = 'promptToBucket',
	promptApply = 'promptApply',
	transferInProgress = 'transferInProgress',
}

interface StateSettings {
	unitSelectState: EElementState;
	fromCardState: EElementState;
	amountCardState: EElementState;
	toCardState: EElementState;
	phraseState: EElementState;
	applyState: EElementState;
}

const STATES_SETTINGS: Record<EMarkdownTransferFormState, StateSettings> = {
	promptUnit: {
		unitSelectState: EElementState.focused,
		fromCardState: EElementState.disabled,
		toCardState: EElementState.disabled,
		amountCardState: EElementState.disabled,
		phraseState: EElementState.disabled,
		applyState: EElementState.disabled,
	},
	promptFromBucket: {
		unitSelectState: EElementState.default,
		fromCardState: EElementState.focused,
		toCardState: EElementState.disabled,
		amountCardState: EElementState.disabled,
		phraseState: EElementState.default,
		applyState: EElementState.disabled,
	},
	promptToBucket: {
		unitSelectState: EElementState.default,
		fromCardState: EElementState.default,
		toCardState: EElementState.focused,
		amountCardState: EElementState.disabled,
		phraseState: EElementState.default,
		applyState: EElementState.disabled,
	},
	promptAmount: {
		unitSelectState: EElementState.default,
		fromCardState: EElementState.default,
		toCardState: EElementState.default,
		amountCardState: EElementState.focused,
		phraseState: EElementState.default,
		applyState: EElementState.disabled,
	},
	promptApply: {
		unitSelectState: EElementState.default,
		fromCardState: EElementState.background,
		amountCardState: EElementState.background,
		toCardState: EElementState.background,
		phraseState: EElementState.focused,
		applyState: EElementState.default,
	},
	transferInProgress: {
		unitSelectState: EElementState.disabled,
		fromCardState: EElementState.disabled,
		amountCardState: EElementState.disabled,
		toCardState: EElementState.disabled,
		phraseState: EElementState.focused,
		applyState: EElementState.disabled,
	},
};

enum EEvent {
	onChangeUnit = 'onChangeUnit',
	onChangeFrom = 'onChangeFrom',
	onChangeAmount = 'onChangeAmount',
	onChangeTo = 'onChangeTo',
	onClickFrom = 'onClickFrom',
	onClickAmount = 'onClickAmount',
	onClickTo = 'onClickTo',
	onBlurAmount = 'onBlurAmount',
	onConfirmAmount = 'onConfirmAmount',
}

const getSettings = (state: EMarkdownTransferFormState): StateSettings => {
	return STATES_SETTINGS[state];
};

const validateInteger = (
	toValidate: string,
): { ok: boolean; validValue: number } => {
	if (toValidate === null) return { ok: false, validValue: NaN };

	const regex = /^[0-9]+$/;
	const parsedInt = parseInt(toValidate, 10);
	const result = regex.test(toValidate) && !Number.isNaN(parsedInt);
	return { ok: result, validValue: result ? parsedInt : NaN };
};

const validateIntegerMinMax = (
	toValidate: string,
	min: number,
	max: number,
) => {
	const internalMax = Math.max(min, max);
	const internalMin = Math.min(min, max);
	const result = validateInteger(toValidate);
	if (result.ok) {
		if (result.validValue > internalMax) {
			result.validValue = internalMax;
		} else if (result.validValue < internalMin) {
			result.validValue = internalMin;
		}
	}
	return result;
};

const toInputValue = (
	internalValue: MarkdownBucketDataType['markdown_int'] | null,
): string => (internalValue !== null ? `${internalValue}%` : '...');
const toDecimalValue = (
	markdown: MarkdownBucketDataType['markdown_int'] | null,
): number => (markdown !== null ? Number((markdown / 100).toFixed(2)) : NaN);
/**
 * Example:
 * - input: [ { key: "Category_1", value:[ "Coats" ] } ]
 * - output: [ [ 'filter': '"id":"Category_1","value":[ "Coats" ]' } ] ]
 */

const checkState = (
	state: EMarkdownTransferFormState,
	unit: EUnit | null,
	setState: Function,
) => {
	if (
		state === EMarkdownTransferFormState.promptUnit &&
		(unit === EUnit.Products || unit === EUnit.Stock)
	) {
		setState(EMarkdownTransferFormState.promptFromBucket);
	}
};

const MarkdownTransferModal: FunctionComponent<Props> = (props) => {
	const { close: rawClose } = useModal();
	const close = () => {
		if (rawClose !== undefined) {
			rawClose();
		}
	};
	const { activeStrategy } = useStrategyStore();
	const { activeChannel } = useChannelStore();
	const [unit, setUnit] = useState<EUnit | null>(EUnit.Products);
	const [from, setFrom] = useState<
		MarkdownBucketDataType['markdown_int'] | null
	>(null);
	const [to, setTo] = useState<MarkdownBucketDataType['markdown_int'] | null>(
		null,
	);
	const [amountDirty, setAmountDirty] = useState<string | null>('');
	const [amount, setAmountInternal] = useState<number | null>(null);

	const [amountError] = useState<string>(''); // no really used right now
	const setAmount = (newAmount: number | null) => {
		setAmountInternal(newAmount);
		setAmountDirty(typeof newAmount === 'number' ? String(newAmount) : '');
	};

	// Do not render without an active strategy
	if (activeStrategy === null) {
		throw new Error('Please create a strategy first');
	}

	const validateDirtyAmount = (max: number): boolean => {
		if (amountDirty === null) {
			return false;
		}
		const validationResult = validateIntegerMinMax(
			amountDirty,
			Math.min(max, 1),
			max,
		);
		if (validationResult.ok) {
			setAmount(validationResult.validValue);
			setAmountDirty(validationResult.validValue.toString());
			return true;
		}
		setAmountDirty((amount ?? '').toString());
		return false;
	};

	const [state, setStateInternal] = useState<EMarkdownTransferFormState>(
		EMarkdownTransferFormState.promptUnit,
	);

	const queryClient = useQueryClient();

	const activeFiltersQueryKey = Array.from(props.activeFilters.values()).map(
		(filter) => {
			return [
				[filter.id, JSON.parse(JSON.stringify(filter.value))].join(
					' is ',
				),
			];
		},
	);

	const bucketsQuery = useMarkdownBucketsQuery({
		strategy_id: activeStrategy,
		filters: filterMapToSearchParams(props.activeFilters),
	});

	const numberTransfersQuery = useChannelQuery<number, unknown, number>(
		['amount', unit?.toString(), from, to, activeFiltersQueryKey],
		() => {
			return GET_NUMBER_TRANSFERS_BUSINESS_RULES({
				strategyId: activeStrategy,
				from: toDecimalValue(from),
				to: toDecimalValue(to),
				filters: filterMapToSearchParams(props.activeFilters),
				unit,
			});
		},
		{
			onSuccess: (data: number) => {
				validateDirtyAmount(data);
			},
			enabled: from !== null && to !== null,
			staleTime: 120 * 1000,
		},
	);

	const toast = useToast();
	const stateSettings = getSettings(state);
	const confirmAmountButtonRef = useRef<HTMLButtonElement>(null);

	const setState = (nextState: EMarkdownTransferFormState) => {
		if (nextState === EMarkdownTransferFormState.promptFromBucket) {
			setFrom(null);
			setAmount(null);
			setTo(null);
		}

		setStateInternal(nextState);
	};

	const onEventHappens = (type: EEvent, value: any = null) => {
		if (state === EMarkdownTransferFormState.transferInProgress) {
			// All events ignored while transfering
			return;
		}
		if (type === EEvent.onChangeUnit) {
			setUnit(value as EUnit); // what if value is not a valid EUnit?
			setState(EMarkdownTransferFormState.promptFromBucket);
		}

		if (type === EEvent.onChangeFrom && value !== null) {
			if (state === EMarkdownTransferFormState.promptFromBucket) {
				setState(EMarkdownTransferFormState.promptToBucket);
			} else {
				const selectedBucket = (bucketsQuery.data ?? []).find(
					(bucket) => bucket.markdown_int === value,
				);

				const amountCap =
					(unit === 'products'
						? selectedBucket?.pid_count
						: selectedBucket?.stock_sum) ?? Infinity;

				if (amount !== null && amount > amountCap) {
					setAmount(amountCap);
				}
			}

			setFrom(value);
		}

		if (type === EEvent.onChangeTo && value !== null) {
			setTo(value);
			setState(EMarkdownTransferFormState.promptAmount);
		}

		if (type === EEvent.onBlurAmount) {
			if (!numberTransfersQuery.isSuccess) {
				return;
			}

			validateDirtyAmount(numberTransfersQuery.data);
		}

		if (type === EEvent.onConfirmAmount) {
			if (
				!numberTransfersQuery.isFetching &&
				numberTransfersQuery.isSuccess &&
				numberTransfersQuery.data
			) {
				if (
					validateDirtyAmount(numberTransfersQuery.data) &&
					state === EMarkdownTransferFormState.promptAmount
				) {
					setState(EMarkdownTransferFormState.promptApply);
				}
			}
		}

		if (state === EMarkdownTransferFormState.promptApply) {
			if (
				type === EEvent.onClickFrom ||
				type === EEvent.onClickAmount ||
				type === EEvent.onClickTo
			) {
				setState(EMarkdownTransferFormState.promptAmount);
			}
		}
	};

	const handleTransferClicked = async () => {
		if (from === null || to === null || amount === null || unit === null) {
			return;
		}

		setState(EMarkdownTransferFormState.transferInProgress);
		const loadingToast = toast.show('Markdown transfer in progresss...', {
			lifeSpan: -1,
			type: 'loading',
		});

		props.transferMarkdownFn(
			{
				strategyId: activeStrategy,
				filterSearchParams: filterMapToSearchParams(
					props.activeFilters,
				),
				payload: {
					from_bucket: toDecimalValue(from),
					to_bucket: toDecimalValue(to),
					units: unit,
					amount,
				},
			},
			{
				onSettled: () => {
					close();
				},
				onSuccess: () => {
					toast.close(loadingToast);
					props.cumulRef.current?.refreshData(); // if this wouldn't work because of possible caching then use this endpoint: https://developer.luzmo.com/api/updateData (maybe call this on the backend while processing the markdown transfer above )
					const bucketQueryKey = constructChannelQueryKey(
						activeChannel,
						['buckets'],
					);
					const amountQueryKey = constructChannelQueryKey(
						activeChannel,
						['amount'],
					);
					queryClient.invalidateQueries({ queryKey: bucketQueryKey });
					queryClient.invalidateQueries({ queryKey: amountQueryKey });
					toast.show(
						'Markdown transfer completed! Dashboard is loading new data...',
						{
							type: 'success',
						},
					);
				},
				onError: () => {
					toast.close(loadingToast);
					toast.show('Markdown transfer failed...', {
						type: 'error',
					});
				},
			},
		);
	};

	checkState(state, unit, setState);
	const isLoading = bucketsQuery.isFetching;

	return (
		<Modal.Root className="w-screen max-w-[1080px] bg-mi-neutral-lightest">
			<Modal.Content>
				<header className="mb-8 border-b pb-4">
					<Title size="h2-like" font="serif" color="forest" as="h2">
						Change markdown distribution
					</Title>
					<Text className="font-normal" type="secondary">
						Easily change the markdown distribution of your products
					</Text>
				</header>
				<MarkdownTransferSelection>
					<MarkdownTransferRadios
						className="mb-8"
						disabled={
							stateSettings.unitSelectState ===
							EElementState.disabled
						}
						value={unit}
						options={[EUnit.Products, EUnit.Stock]}
						onChange={(value: string) =>
							onEventHappens(EEvent.onChangeUnit, value)
						}
					/>
					<MarkdownTransferActiveFilters
						filters={activeFiltersQueryKey}
					/>
				</MarkdownTransferSelection>
				<div className="grid grid-cols-[1fr_1fr_1fr] gap-4">
					<MarkdownTransferCard
						title="From bucket"
						text={`The amount of ${
							unit ?? '...'
						} with this markdown will decrease(↘)`}
						className=""
						state={
							isLoading ? 'disabled' : stateSettings.fromCardState
						}
						onClick={() => {
							onEventHappens(EEvent.onClickFrom);
						}}
					>
						{bucketsQuery.isFetching ? (
							<div className="flex gap-2">
								<Text type="secondary">Loading...</Text>
								<LoadingSpinner variant="md" />
							</div>
						) : (
							<MarkdownTransferBucketsDropdown
								value={from}
								onChange={(value) =>
									onEventHappens(EEvent.onChangeFrom, value)
								}
								bucketItems={(bucketsQuery.data ?? []).map(
									(bucket) => ({
										...bucket,
										disabled:
											bucket.markdown_int === to ||
											bucket.pid_count === 0 ||
											bucket.stock_sum === 0,
									}),
								)}
								unit={unit}
							/>
						)}
					</MarkdownTransferCard>

					<MarkdownTransferCard
						title="To Bucket"
						text={`The amount of ${
							unit ?? '...'
						} with this markdown will increase(↗)`}
						className=""
						state={
							isLoading ? 'disabled' : stateSettings.toCardState
						}
						onClick={() => onEventHappens(EEvent.onClickTo)}
					>
						{bucketsQuery.isFetching ? (
							<div className="flex gap-2">
								<Text type="secondary">Loading...</Text>
								<LoadingSpinner variant="md" />
							</div>
						) : (
							<MarkdownTransferBucketsDropdown
								className=""
								value={to}
								onChange={(value) =>
									onEventHappens(EEvent.onChangeTo, value)
								}
								bucketItems={(bucketsQuery.data ?? []).map(
									(bucket) => ({
										...bucket,
										disabled: bucket.markdown_int === from,
									}),
								)}
								unit={unit}
							/>
						)}
					</MarkdownTransferCard>
					<MarkdownTransferCard
						title="Amount"
						text={`How ${
							unit === 'products' ? 'many products' : 'much stock'
						} do you want to transfer?`}
						className="w-56"
						state={
							isLoading
								? 'disabled'
								: stateSettings.amountCardState
						}
						onClick={() => onEventHappens(EEvent.onClickAmount)}
					>
						<div className="flex gap-2">
							<TextInput
								id="amount"
								className="w-full"
								value={amountDirty ?? ''}
								onChange={(
									e: React.ChangeEvent<HTMLInputElement>,
								) => {
									setAmountDirty(e.currentTarget.value);
								}}
								error={amountError}
								placeholder="Amount"
								type="text"
								endAdornment={undefined}
								startAdornment={undefined}
								size="regular"
								onBlur={() =>
									onEventHappens(EEvent.onBlurAmount)
								}
							/>
							<Button
								disabled={
									numberTransfersQuery.isFetching ||
									stateSettings.amountCardState ===
										EElementState.disabled ||
									stateSettings.amountCardState ===
										EElementState.background
								}
								onClick={() => {
									onEventHappens(EEvent.onConfirmAmount);
								}}
								size="sm"
								ref={confirmAmountButtonRef}
							>
								Next
							</Button>
						</div>
						<div className="mt-1 flex text-xs">
							<Tooltip
								content={((): ReactNodeLike => {
									if (
										from === null ||
										numberTransfersQuery.isFetching
									)
										return 'loading...';

									const fromBucket = (
										bucketsQuery.data ?? []
									).find(
										(bucket) =>
											bucket.markdown_int === from,
									);
									if (fromBucket === undefined) {
										throw new Error(
											'[MarkdownTransferModal] "from" not found in buckets',
										);
									}

									const inBucket =
										unit === 'products'
											? fromBucket.pid_count
											: fromBucket.stock_sum;

									const maxAllowed =
										numberTransfersQuery?.data;

									return maxAllowed !== undefined
										? `${inBucket - maxAllowed} (Out of ${inBucket}) ${unit} are
											not possible to transfer because they would violate your
											business rules.`
										: 'Takes into account your business rules.';
								})()}
							>
								<span className="flex items-end gap-1">
									<Text className="text-gray-500">
										Out of max{' '}
									</Text>
									{numberTransfersQuery?.isFetching ? (
										<LoadingSpinner
											variant="sm"
											hidden={
												!numberTransfersQuery.isFetching
											}
											antiVisualJumping
										/>
									) : (
										<Text className="text-gray-500">
											<strong>
												{numberTransfersQuery?.data}
											</strong>
										</Text>
									)}
									<InfoIcon className="inline-block h-4 px-0.5 text-gray-500" />
								</span>
							</Tooltip>
						</div>
					</MarkdownTransferCard>
				</div>
				<MarkdownTransferBrace state={stateSettings.phraseState} />
				<MarkdownTransferPhrase
					state={stateSettings.phraseState}
					unit={unit}
					fromObject={{
						text: toInputValue(from),
						highlighted:
							state ===
							EMarkdownTransferFormState.promptFromBucket,
					}}
					amountObject={{
						text: amount === null ? '...' : String(amount),
						highlighted:
							state === EMarkdownTransferFormState.promptAmount,
					}}
					toObject={{
						text: toInputValue(to),
						highlighted:
							state === EMarkdownTransferFormState.promptToBucket,
					}}
				/>
			</Modal.Content>
			<Modal.Actions className="bg-transparent">
				<div className="flex justify-between">
					<Button
						variant="soft"
						color="neutral"
						onClick={() => {
							close();
						}}
					>
						Cancel
					</Button>
					<Button
						disabled={
							stateSettings.applyState === EElementState.disabled
						}
						onClick={() => handleTransferClicked()}
					>
						{state ===
						EMarkdownTransferFormState.transferInProgress ? (
							<span className="flex items-center gap-2">
								Transfering {amount ?? ''} {unit ?? ''}...
								<LoadingSpinner />
							</span>
						) : (
							`Transfer ${amount ?? ''} ${unit ?? ''}`
						)}
					</Button>
				</div>
			</Modal.Actions>
		</Modal.Root>
	);
};
export default MarkdownTransferModal;
