import { cn, Text, TextSize } from 'crunch-components';
import { FunctionComponent } from 'react';
import {
	EElementState,
	EUnit,
	PhraseObject,
} from '../types/MarkdownTransferTypes';

interface PhraseProps {
	state: EElementState;
	unit: EUnit | null;
	fromObject: PhraseObject;
	toObject: PhraseObject;
	amountObject: PhraseObject;
}

interface DynamicTextProps {
	highlightObject: PhraseObject;
	size: TextSize;
}

const DynamicText: FunctionComponent<DynamicTextProps> = (props) => {
	const isMissing = typeof props.highlightObject?.text !== 'string';
	const replacement = '...';
	return (
		<span className="grid grid-cols-1 grid-rows-1">
			<Text
				className={cn(
					isMissing && 'text-gray-400',
					!isMissing &&
						!props.highlightObject.highlighted &&
						'text-mi-neutral-darker',
					props.highlightObject.highlighted
						? 'text-ca-purlple bg-mi-velvet-lilac text-white shadow-ca-glow'
						: 'bg-mi-neutral-light',
					'z-10 col-start-1 row-start-1 rounded px-2',
				)}
				size={props.size}
			>
				{typeof props.highlightObject?.text !== 'string'
					? replacement
					: props.highlightObject.text}
			</Text>
			{props.highlightObject.highlighted && (
				<div className="col-start-1 row-start-1 animate-ping rounded bg-mi-velvet-lilac opacity-40" />
			)}
		</span>
	);
};

const MarkdownTransferPhrase: FunctionComponent<PhraseProps> = (props) => {
	const defaultHighlightObject: PhraseObject = {
		replacement: '...',
		text: null,
		highlighted: false,
	};

	const amountObject: PhraseObject =
		props.amountObject ?? defaultHighlightObject;
	const toObject: PhraseObject = props.toObject ?? defaultHighlightObject;
	const fromObject: PhraseObject = props.fromObject ?? defaultHighlightObject;

	const textSize = 'text-lg';
	const wrapInText = (contents: string) => (
		<Text size="text-lg" type="secondary">
			{contents}
		</Text>
	);

	return (
		<div className="flex justify-center">
			<div
				className={cn(
					props.state === 'focused'
						? 'shadow-mi-glow border-mi-velvet-lilac'
						: 'border-mi-neutral shadow-[0px_0px_0px_0px]',
					'w-min rounded-lg border px-4 py-2 transition-opacity transition-shadow duration-500',
				)}
			>
				<div className="flex w-max whitespace-pre">
					{wrapInText(`'I will transfer `)}
					<DynamicText
						size={textSize}
						highlightObject={amountObject}
					/>
					{wrapInText(` ${props.unit ? props.unit : '?'} from `)}
					<DynamicText size={textSize} highlightObject={fromObject} />
					{wrapInText(' markdown to ')}
					<DynamicText size={textSize} highlightObject={toObject} />
					{wrapInText(` markdown'`)}
				</div>
			</div>
		</div>
	);
};

export default MarkdownTransferPhrase;
