import dayjs from 'dayjs';

import {
	Badge,
	BadgeProps,
	CheckmarkIcon,
	CrossIcon,
	InfoIcon,
	ProgressIcon,
} from 'crunch-components';
import { ElementType } from 'react';
import TimeSince from '../../strategy/v2/TimeSince';
import { UploadStatus, UploadValidationErrors } from '../types/uploads';
import UploadsValidationTooltip from './UploadsValidationTooltip';

export type UploadStatusBadgeProps = {
	status?: UploadStatus;
	validationErrors?: UploadValidationErrors;
	createdAt?: string;
	updatedAt?: string;
};

function mapBadge(
	status: UploadStatusBadgeProps['status'],
	createdAt?: string,
	updatedAt?: string,
): {
	variant: BadgeProps['variant'];
	message: string;
	icon?: ElementType;
	sinceDate?: string;
} {
	switch (status) {
		case undefined:
			return { variant: 'neutral', message: 'No upload' };
		case 'failed':
			return {
				variant: 'destructive',
				message: 'Internal error',
				icon: CrossIcon,
				sinceDate: updatedAt,
			};
		case 'data_validation_failed':
			return {
				variant: 'destructive',
				message: 'Validation failed',
				icon: InfoIcon,
				sinceDate: updatedAt,
			};
		case 'finished':
			return {
				variant: 'success',
				message: 'Success',
				sinceDate: updatedAt,
				icon: CheckmarkIcon,
			};
		default:
			return {
				variant: 'colorPurple',
				message: 'Processing',
				icon: ProgressIcon,
				sinceDate: createdAt,
			};
	}
}

export default function UploadsStatusBadge({
	status,
	validationErrors,
	createdAt,
	updatedAt,
}: UploadStatusBadgeProps) {
	const { variant, message, icon, sinceDate } = mapBadge(
		status,
		createdAt,
		updatedAt,
	);
	const Icon = icon;
	// Tooltip doesn't work with <Badge /> directly (reason unknown) so we wrap it with a div.
	return (
		<div className="flex flex-col justify-center gap-1 p-2">
			<UploadsValidationTooltip validationErrors={validationErrors}>
				<div className="w-fit">
					<Badge
						className="flex w-40 flex-row justify-center gap-1 group-hover/row:brightness-[97%]"
						variant={variant}
					>
						{Icon !== undefined && <Icon className="h-3 w-auto" />}
						<span>{message}</span>
					</Badge>
				</div>
			</UploadsValidationTooltip>
			{sinceDate !== undefined && (
				<span className="-mb-1.5 text-xxs text-ca-gray-500">
					<TimeSince time={dayjs(new Date(sinceDate))} />
				</span>
			)}
		</div>
	);
}
