import useBusinessRulesQuery, {
	BusinessRulesDataType,
	useBusinessRulePatch,
} from 'hooks/queries/useBusinessRulesQuery';

import StratosCheckbox from 'components/StratosCheckbox/StratosCheckbox';
import {
	CaretIcon,
	Checkbox,
	CheckboxValue,
	CHILD_ROWS_DEFAULT_STYLING,
	cn,
	DuplicateIcon,
	EditIcon,
	InfoIcon,
	KebabMenu,
	TableV2,
	Text,
	Tooltip,
	TrashIcon,
} from 'crunch-components';
import useBusinessRuleDelete from 'hooks/mutations/useBusinessRuleDelete';
import useBusinessRulesPriorityMutation from 'hooks/mutations/useBusinessRulesPriorityMutation';
import { useBusinessRuleCopy } from 'hooks/queries/useBusinessRuleCopy';
import usePhasesQuery from 'hooks/queries/usePhasesQuery';
import { ComponentProps, useState } from 'react';
import { useEditModalBusinessRule } from '../BusinessRules/components/BusinessRuleCreateEditModalBase';
import BusinessRuleSettingsBadge from '../BusinessRules/components/BusinessRuleSettingsBadge';

type RowDataType = BusinessRulesDataType['items'][number];

const PHASE_COL_ID_STARTS_WITH = 'phase_';

const constructPhaseColumnId = (phaseId: string) =>
	`${PHASE_COL_ID_STARTS_WITH}${phaseId}`;

/**
 * returns name of phase linked to this column
 */
const destructurePhaseColumnId = (columnId: string) =>
	columnId.substring(PHASE_COL_ID_STARTS_WITH.length);

function Cell(props: {
	row: RowDataType;
	rowIsOpen: boolean;
	setRowIsOpen: (isOpen: boolean) => void;
	columnId: ComponentProps<typeof TableV2>['headings'][number]['id'];
}) {
	const patchMutation = useBusinessRulePatch();
	const deleteMutation = useBusinessRuleDelete(props.row.id);
	const copyMutation = useBusinessRuleCopy(props.row.id);

	switch (props.columnId) {
		case 'dnd':
			return <div>...</div>;
		case 'priority':
			return <Text type="secondary">{props.row.priority + 1}</Text>;
		case 'name': {
			const { open: openEdit } = useEditModalBusinessRule();

			return (
				<div className="flex items-center">
					<span className="flex-grow" key="title">
						{props.row.title}
					</span>
					<KebabMenu
						closeDelayMs={250}
						options={[
							{
								Icon: EditIcon,
								label: 'Edit',
								onClick: () => {
									openEdit(props.row.id);
								},
							},
							{
								Icon: DuplicateIcon,
								label: 'Copy',
								onClick: () => {
									copyMutation.mutate();
								},
							},
							{
								Icon: TrashIcon,
								label: 'Delete',
								onClick: () => {
									deleteMutation.mutate();
								},
								hasSafety: true,
							},
						]}
					/>
				</div>
			);
		}

		case 'settings':
			return (
				<BusinessRuleSettingsBadge
					type={props.row.action_type}
					settings={(() => {
						switch (props.row.action_type) {
							case 'Global':
								return {
									global_action: props.row.global_action,
								};
							case 'Custom_average':
								return {
									custom_average_action:
										props.row.custom_average_action,
								};
							case 'Custom_distribution':
								return {
									custom_distribution_action:
										props.row.custom_distribution_action,
								};
							case 'Custom_fixed':
								return {
									custom_fixed_action:
										props.row.custom_fixed_action,
								};
							case 'Custom_max_increase':
								return {
									custom_max_increase_action:
										props.row.custom_max_increase_action,
								};
							case 'Custom_min_change':
								return {
									custom_min_change_action:
										props.row.custom_min_change_action,
								};
							case 'Custom_minmax':
								return {
									custom_minmax_action:
										props.row.custom_minmax_action,
								};
							case 'Custom_possible':
								return {
									custom_possible_action:
										props.row.custom_possible_action,
								};
							default:
								return { type: undefined };
						}
					})()}
				/>
			);
		default:
			if (
				typeof props.columnId === 'string' &&
				props.columnId.startsWith(PHASE_COL_ID_STARTS_WITH)
			) {
				const phaseId = destructurePhaseColumnId(props.columnId);
				const phase = (props.row.phase_assignments ?? []).find(
					(phaseHay) => phaseHay.id === phaseId,
				);

				const wrapperCn = 'h-12 flex items-center justify-center';

				if (phase === undefined) {
					console.warn(
						'(msg 2) expect this column & phase id to be fine but assignments to be missing it',
						{
							columnId: props.columnId,
							phaseId,
							br_phase_assignments: props.row.phase_assignments,
						},
					);

					return (
						<div className={wrapperCn}>
							<StratosCheckbox disabled checked={true} />
						</div>
					);
				}

				const nbActiveStrategies: number = (
					phase.strategies ?? []
				).reduce((acc, strategy) => acc + Number(strategy.active), 0);
				const nbStrategies = phase.strategies.length;
				let aggregatedValue: CheckboxValue | undefined;
				if (nbActiveStrategies === 0) {
					aggregatedValue = CheckboxValue.unchecked;
				} else if (nbActiveStrategies === nbStrategies) {
					aggregatedValue = CheckboxValue.checked;
				} else {
					aggregatedValue = CheckboxValue.mixed;
				}

				const openRowOnClick =
					aggregatedValue === CheckboxValue.mixed && !props.rowIsOpen;

				return (
					<div key={props.columnId} className={wrapperCn}>
						<div
							role="button"
							tabIndex={0}
							className="group/checkbox relative flex"
							onKeyDown={(e) => {
								e.stopPropagation();
								if (openRowOnClick) {
									props.setRowIsOpen(!props.rowIsOpen);
								}
							}}
							onClick={(e) => {
								e.stopPropagation();
								if (openRowOnClick) {
									props.setRowIsOpen(!props.rowIsOpen);
								}
							}}
						>
							<div className="grid">
								{aggregatedValue === CheckboxValue.mixed && (
									<Text
										className={cn(
											'relative col-start-1 row-start-1 flex h-0 max-w-0 flex-col justify-center opacity-0 transition-all duration-500',
											openRowOnClick
												? 'group-hover/checkbox:max-w-[65px] group-hover/checkbox:opacity-100'
												: 'pointer-events-none',
										)}
									>
										More info
										<span className="h-0 text-xxs text-ca-gray-400">
											(expand row)
										</span>
									</Text>
								)}

								<div
									className={cn(
										'col-start-1 row-start-1 transition-opacity duration-200',
										openRowOnClick &&
											'group-hover/checkbox:opacity-0',
									)}
								>
									<StratosCheckbox
										variant="filled"
										checked={aggregatedValue}
										overrideOnClick={
											openRowOnClick
												? () => {}
												: undefined
										}
										onChange={() => {
											// checked    => becomes false
											// unchecked  => becomes true
											// mixed      => becomes true
											const newIsCheckedAggregate: boolean =
												aggregatedValue !==
												CheckboxValue.checked;

											patchMutation.mutate({
												businessRuleId: props.row.id,
												body: {
													phase_assignments: [
														{
															id: phase.id,
															strategies:
																phase.strategies.map(
																	(
																		strategy,
																	) => ({
																		id: strategy.id,
																		active: newIsCheckedAggregate,
																	}),
																),
														},
													],
												},
											});
										}}
									/>
								</div>
							</div>

							<div className="w-0 max-w-0">
								<CaretIcon
									orientation="down"
									className={cn(
										'h-4 w-4 text-ca-purple transition-all group-hover/checkbox:translate-y-1/4',
										!openRowOnClick && 'text-opacity-0',
									)}
								/>
							</div>
						</div>
					</div>
				);
			}

			return <div className="w-16" />;
	}
}

type InnerShadowType = 'top' | 'middle' | 'bottom';
const shadowDict: Record<InnerShadowType, string> = {
	top: 'shadow-[inset_0px_20px_20px_-20px_#00000024]',
	bottom: 'shadow-[inset_0px_-20px_20px_-20px_#00000024]',
	middle: '',
} as const;

const StrategyAssignmentRow = (props: {
	key: string;
	strategyName: string;
	shadowType: InnerShadowType;
	assignments: {
		phaseId: string;
		strategyId: string;
		active: boolean;
		onChange: ComponentProps<typeof Checkbox>['onChange'];
	}[];
}) => {
	return (
		<tr
			key={props.key}
			className={cn(
				CHILD_ROWS_DEFAULT_STYLING.childRowClassName,
				'h-12',
				shadowDict[props.shadowType],
			)}
		>
			<td
				colSpan={3}
				className="h-12 text-right text-xs text-ca-black"
				key="strategyName"
			>
				{props.strategyName}
			</td>

			{props.assignments.map((assignment) => (
				<td key={assignment.phaseId}>
					<div className="flex justify-center">
						<StratosCheckbox
							variant="outline"
							checked={assignment.active}
							onChange={assignment.onChange}
						/>
					</div>
				</td>
			))}
			<td />
		</tr>
	);
};

const renderOpenRow = (
	row: BusinessRulesDataType['items'][number],
	patchMutation: ReturnType<typeof useBusinessRulePatch>,
) => {
	return (
		<>
			{row?.phase_assignments?.at(0) &&
				row.phase_assignments[0].strategies.map(
					(
						{ id: strategyId, name: strategyName },
						strategyIndex,
						strategies,
					) => {
						return (
							<StrategyAssignmentRow
								key={`${row.title}${strategyName}`}
								shadowType={
									/* eslint-disable-next-line */
									strategyIndex === 0
										? 'top'
										: strategyIndex ===
											  strategies.length - 1
											? 'bottom'
											: 'middle'
								}
								strategyName={strategyName}
								assignments={(row.phase_assignments ?? []).map(
									(phase) => {
										return {
											strategyId,
											phaseId: phase.id,
											active: phase.strategies[
												strategyIndex
											].active,
											onChange: (checked) => {
												const strategyToModify =
													phase.strategies.find(
														(newStrategy) =>
															newStrategy.id ===
															strategyId,
													);
												if (!strategyToModify) return;

												const strategyClone = {
													...strategyToModify,
												};
												strategyClone.active = checked;
												const modifiedStrategies =
													phase.strategies.map(
														(unmodifiedStrategy) =>
															unmodifiedStrategy.id ===
															strategyClone.id
																? strategyClone
																: unmodifiedStrategy,
													);
												patchMutation.mutate({
													businessRuleId: row.id,
													body: {
														phase_assignments: [
															{
																id: phase.id,
																strategies:
																	modifiedStrategies,
															},
														],
													},
												});
											},
										};
									},
								)}
							/>
						);
					},
				)}
		</>
	);
};

const TableBusinessRules = () => {
	const rulesQuery = useBusinessRulesQuery();
	const phasesQuery = usePhasesQuery();
	const patchMutation = useBusinessRulePatch();
	const priorityMutation = useBusinessRulesPriorityMutation();
	const loading = rulesQuery.isLoading || phasesQuery.isLoading;

	const phasesHeadings = (phasesQuery.data ?? [])
		.filter((phase) => !phase.completed)
		.slice(0, window._ENV_.REACT_APP_MAX_SCHEDULED_PHASES)
		.map((phase) => {
			return {
				id: constructPhaseColumnId(phase.id),
				label: phase.name,
				tooltip: phase.start_date,
				className: 'text-mi-neutral-dark',
				align: 'center',
				maxWidth: loading ? '20px' : undefined, // this doesn't work
			};
		});

	const HEADINGS = [
		{
			id: 'dragdrop',
			className: 'bg-mi-neutral-light mix-blend-multiply',
			maxWidth: loading ? '20px' : undefined,
		},
		{
			id: 'priority',
			label: 'Priority',
			className: 'text-mi-neutral-dark',
			align: 'center',
			tooltip:
				'The business rule at the top of the table has the highest priority and will always be applied. Rules that are lower in the table will only be applied if they do not conflict with prior rules.',
			maxWidth: loading ? '20px' : undefined,
		},
		{
			id: 'name',
			label: 'Name',
			className: 'text-mi-neutral-dark',
			align: 'left',
		},
		...phasesHeadings,
		{
			id: 'settings',
			label: 'Settings',
			className: 'text-mi-neutral-dark',
			align: 'left',
		},
	];

	const [openRows, setOpenRows] = useState<
		BusinessRulesDataType['items'][number]['id'][]
	>([]);

	const toggleRowOpen = (businessRuleId: (typeof openRows)[number]) => {
		if (openRows.includes(businessRuleId)) {
			const index = openRows.findIndex(
				(rowHay) => businessRuleId === rowHay,
			);
			const clone = [...openRows];
			clone.splice(index, 1);
			setOpenRows(clone);
			return;
		}
		setOpenRows([...openRows, businessRuleId]);
	};
	const cleanedBusinessRules = (rulesQuery?.data?.items ?? []).map((item) => {
		return {
			...item,
			phase_assignments: item.phase_assignments
				?.filter((phase) => !phase.completed)
				.slice(0, window._ENV_.REACT_APP_MAX_SCHEDULED_PHASES),
		};
	});

	const productRows = cleanedBusinessRules.filter(
		(rowHay) => rowHay.business_rule_type === 'product_level',
	);
	const groupRows = cleanedBusinessRules.filter(
		(rowHay) => rowHay.business_rule_type === 'assortment_level',
	);
	const rows = [...productRows, ...groupRows];
	return (
		<TableV2
			className="text-mi-neutral-dark"
			loading={loading}
			// @ts-ignore
			onDragChange={(keys) => {
				priorityMutation.mutate(keys);
			}}
			headings={HEADINGS}
			rows={rows}
			separatorRows={[
				{
					position: 0,
					key: 'product',
					colSpan: 1000,
					renderSeparatorRow: () => {
						return (
							<div className="pl-3">
								<Tooltip
									content={
										<span>
											These are strict rules applied to
											individual products.
										</span>
									}
								>
									<span className="inline-flex items-center gap-2">
										<span>
											<strong>Product level</strong>{' '}
											business rules
										</span>
										<InfoIcon className="h-4 text-gray-500" />
									</span>
								</Tooltip>
							</div>
						);
					},
				},
				{
					position: productRows.length,
					key: 'group',
					colSpan: 1000,
					renderSeparatorRow: () => {
						return (
							<div className="pl-3">
								<Tooltip
									content={
										<span>
											These are flexible guidelines
											applied to a group of products.
										</span>
									}
								>
									<span className="inline-flex items-center gap-2">
										<span>
											<strong>Group level</strong>{' '}
											business rules
										</span>
										<InfoIcon className="h-4 text-gray-500" />
									</span>
								</Tooltip>
							</div>
						);
					},
				},
			]}
			renderCell={(
				// @ts-ignore
				rowData: (typeof rows)[index],
				columnId: ComponentProps<
					typeof TableV2
				>['headings'][number]['id'],
			) => (
				<Cell
					row={rowData}
					rowIsOpen={
						openRows.find(
							(openRowId) => openRowId === rowData.id,
						) !== undefined
					}
					setRowIsOpen={() => {
						toggleRowOpen(rowData.id);
					}}
					columnId={columnId}
				/>
			)}
			rowKey="id"
			open={openRows}
			renderOpenTr={(row) => renderOpenRow(row, patchMutation)}
			onRowClick={(row: BusinessRulesDataType['items'][number]) => {
				toggleRowOpen(row.id);
			}}
			dndRowTypes={productRows
				.map((product) => product.business_rule_type)
				.concat(groupRows.map((group) => group.business_rule_type))}
			dndRowAccepts={productRows
				.map((product) => [product.business_rule_type])
				.concat(groupRows.map((group) => [group.business_rule_type]))}
		/>
	);
};

export default TableBusinessRules;
