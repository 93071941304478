/*eslint-disable */
import Title from 'components/Title/Title';
import { cn } from 'crunch-components';

const MarkdownTransferCard = ({
	title,
	text,
	children,
	className,
	onClick,
	state = 'default',
}) => {
	const jsx = (
		<div
			className={cn(
				state === 'background' && 'opacity-70',
				state === 'focused' && ' border-mi-velvet-lilac shadow-mi-velvet-lilac/50 shadow-[0px_0px_25px_-5px] bg-mi-neutral-lightest',
				state === 'disabled' && 'pointer-events-none',
				state === 'default' && 'text-ca-gray-500 border-mi-velvet-lilac-light',
				state !== 'focused' && ' shadow-[0px_0px_0px_0px] shadow-mi-velvet-lilac/50 bg-mi-neutral-lightest',
				'border text-mi-neutral-darker flex flex-col gap-0 rounded-xl p-5 box-border h-full w-full transition-shadow duration-500 ',
				className
			)}
			onClick={onClick}
		>
			<div className="flex gap-2">
				<Title size='h4-like'color='neutral-darkest' font='sans' as='h4'>{title}</Title>
				{state === 'defualt' && (
					<div className="w-6 h-6 bg-green-600 rounded-full" />
				)}
			</div>
			<span className="text-ca-gray-500 text-base mb-6">{text}</span>
			{children}
		</div>
	);
	return (
		<div
			key={title}
			className={cn(
				state === 'disabled' && 'opacity-30 cursor-not-allowed',
				'transition-opacity duration-300'
			)}
		>
			{jsx}
		</div>
	);
};

export default MarkdownTransferCard;
