import UserManagementHeader from '../components/UserManagementHeader';
import UserManagementTable from '../components/UserManagementTable';

const UserManagementView = () => {
	return (
		<>
			<UserManagementHeader />
			<div className="mt-10">
				<UserManagementTable />
			</div>
		</>
	);
};

export default UserManagementView;
