import ChannelSelector from 'components/ChannelSelector/ChannelSelector';
import Title from 'components/Title/Title';

const UploadsHeader = () => (
	<div className="flex justify-between">
		<div className="flex flex-col gap-2">
			<Title size="h1-like" font="serif" color="forest" as="h1">
				Uploads
			</Title>
		</div>
		<div className="flex flex-col gap-2">
			<ChannelSelector />
		</div>
	</div>
);

export default UploadsHeader;
