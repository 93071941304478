/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';

import { Button, Checkbox, CheckmarkIcon, cn } from 'crunch-components';
import {
	CheckboxCellProps,
	ColumnDataType,
	GridDispatchType,
	GridState,
	RowDataType,
} from './grid';

export type CheckboxGridProps = {
	rows: RowDataType[];
	columns: ColumnDataType[];
	gridState: GridState;
	dispatchGrid: GridDispatchType;
	className?: string;
};

export const CheckboxGrid = ({
	rows,
	columns,
	gridState,
	dispatchGrid: dispatch,
	className,
}: CheckboxGridProps) => {
	const handleCheckboxChange: CheckboxCellProps['onChange'] = (args) => {
		dispatch({
			type: 'TOGGLE_CHECKBOX',
			payload: args,
		});
	};

	const toggleRow = (args: { rowId: string }) => {
		dispatch({ type: 'TOGGLE_ROW', payload: args });
	};

	const toggleColumn = (args: { columnId: string }) => {
		dispatch({ type: 'TOGGLE_COLUMN', payload: args });
	};

	return (
		<div
			className={cn(
				`grid checkbox-grid-${columns.length + 1}`,
				className,
			)}
		>
			<div />
			{columns.map((column) => (
				<div
					key={column.id}
					className="flex flex-row items-center px-2 py-1 pl-9"
				>
					<span className="w-[100px] break-words text-center text-xs text-ca-gray-500">
						{column.name}
					</span>
					<Button
						title="Toggle Column"
						variant="unstyled"
						size="small"
						className={cn(
							'flex flex-shrink-0 items-center justify-center p-2 hover:bg-ca-gray-bg',
						)}
						onClick={() => toggleColumn({ columnId: column.id })}
					>
						<CheckmarkIcon className="h-2 w-auto text-ca-gray-500" />
					</Button>
				</div>
			))}
			{rows.map((row, rowIndex) => {
				const rowId = row.id;
				return (
					<React.Fragment key={rowId}>
						<div
							className={cn(
								'flex flex-row items-center p-2',
								rowIndex % 2 === 1 ? 'bg-gray-100' : 'bg-white',
							)}
						>
							<span className="whitespace-nowrap text-xs leading-6 text-ca-gray-500">
								{row.name}
							</span>
							<Button
								title="Toggle Row"
								variant="unstyled"
								size="small"
								className={cn(
									'flex-shrink-0 items-center justify-center p-2 hover:bg-ca-gray-bg',
									columns.length <= 1 && 'hidden',
								)}
								onClick={() => toggleRow({ rowId })}
							>
								<CheckmarkIcon className="h-2 w-auto text-ca-gray-500" />
							</Button>
						</div>
						{columns.map((column) => {
							const columnId = column.id;
							const value =
								gridState?.[rowId]?.[columnId] ?? false;
							return (
								<div
									key={`${columnId}`}
									className={cn(
										'flex items-center justify-center',
										rowIndex % 2 === 1
											? 'bg-gray-100'
											: 'bg-white',
									)}
								>
									<Checkbox
										checked={value}
										onChange={(newValue) =>
											handleCheckboxChange({
												rowId,
												columnId,
												checked: newValue,
											})
										}
									/>
								</div>
							);
						})}
					</React.Fragment>
				);
			})}
		</div>
	);
};
