import { cn } from 'crunch-components';
import { NavLink } from 'react-router-dom';

interface Props {
	items: Array<{
		id: string;
		to: string;
		label: string;
	}>;
}

function HeaderNav({ items }: Props) {
	return (
		<div className="flex w-full gap-10 border-b border-ca-silver py-1">
			{items.map((item) => (
				<NavLink
					key={item.id}
					to={item.to}
					className={({ isActive }) =>
						cn(
							'-mb-[5px] border-b py-2 font-semibold transition-colors hover:text-mi-velvet-lilac',
							isActive === true
								? 'border-b-mi-velvet-lilac text-mi-velvet-lilac'
								: 'border-b-transparent text-mi-neutral',
						)
					}
				>
					{item.label}
				</NavLink>
			))}
		</div>
	);
}

export default HeaderNav;
