/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import * as React from 'react';

import Title from 'components/Title/Title';
import {
	Button,
	CheckmarkIcon,
	CrossIcon,
	InputWithLabel,
	Popover,
	PopoverContent,
	PopoverTrigger,
	TextInput,
	Tooltip,
} from 'crunch-components';
import ResidualValueGraph from '../components/ResidualValueGraph';
import { GraphData, GraphDataUndefined } from '../types/strategies';

export type AdvancedSettingsProps = React.PropsWithChildren<{
	onSave: (val: GraphData) => void;
	value: GraphData;
	onChange: (val: GraphData) => void;
}>;

const toInputValue = (internalValue: number) =>
	internalValue === undefined ? '' : Math.round(internalValue).toString();

const toNumber = (inputValue: string) => {
	const parsed = parseFloat(inputValue);
	return Number.isNaN(parsed) ? null : Math.round(parsed);
};

const limits = {
	max: {
		max: 150,
		min: -100,
		validate: (val: number) => val <= 150 && val >= -100,
	},
	min: {
		max: 150,
		min: -100,
		validate: (val: number) => val <= 150 && val >= -100,
	},
	sell: {
		max: 100,
		min: 0,
		validate: (val: number) => val <= 100 && val >= 0,
	},
};

const transformToInputs = (val: GraphDataUndefined) => ({
	max:
		typeof val.max === 'number' && !Number.isNaN(val.max)
			? String(val.max)
			: '',
	min:
		typeof val.min === 'number' && !Number.isNaN(val.min)
			? String(val.min)
			: '',
	sell:
		typeof val.sell === 'number' && !Number.isNaN(val.sell)
			? String(val.sell)
			: '',
});

export const AdvancedSettings = ({
	children,
	value: graphState,
	onChange: setGraphState,
	onSave,
}: AdvancedSettingsProps) => {
	const [inputValues, setInputValues] = React.useState(
		transformToInputs(graphState),
	);
	const [open, setOpen] = React.useState(false);

	const close = () => {
		setOpen(false);
	};

	const save = () => {
		// there is some confusion with types as backend can and will return nulls
		onSave({
			max: toNumber(inputValues.max)!,
			min: toNumber(inputValues.min)!,
			sell: toNumber(inputValues.sell)!,
		});
		// onSave(graphState);
		close();
	};

	// we want to preserve input values when we type `-`
	// TODO this has to be simpler
	React.useEffect(() => {
		const newValues = transformToInputs(graphState);
		setInputValues({
			max:
				graphState.max === null && toNumber(inputValues.max) === null
					? inputValues.max
					: newValues.max,
			min:
				graphState.min === null && toNumber(inputValues.min) === null
					? inputValues.min
					: newValues.min,
			sell:
				graphState.sell === null && toNumber(inputValues.sell) === null
					? inputValues.sell
					: newValues.sell,
		});
	}, [graphState]);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>{children}</PopoverTrigger>
			<PopoverContent
				className="relative w-auto rounded-lg bg-ca-gray-100 p-0 shadow-2xl"
				align="start"
				side="right"
			>
				<div className="px-8 py-6">
					<Title as="h3" color="forest" font="sans" size="h4-like">
						Advanced Markdown Intensity
					</Title>

					<div className="mt-4 flex flex-col">
						<div className="mb-4 flex flex-col gap-y-2">
							<InputWithLabel
								label={
									<span className="text-sm">
										Max residual value
									</span>
								}
								htmlFor="objective_max_residual_vaue"
								labelClassName="w-64 mb-2 md:mb-0"
							>
								<TextInput
									placeholder="..."
									id="objective_max_residual_value"
									type="number"
									min={limits.max.min}
									max={limits.max.max}
									step="1"
									className="w-full md:w-24 lg:w-28"
									value={inputValues.max}
									onChange={(e: any) => {
										// inputs must allow `-` or empty string
										// but this needs to be converted to null in setGraphState
										// thus we are doing probably needlessly complex stuff here
										const newValue = toNumber(
											e.target.value,
										);
										setInputValues({
											...inputValues,
											max: e.target.value,
										});
										// new value must be in range
										// we don't validate null
										if (
											newValue !== null &&
											!limits.max.validate(newValue)
										) {
											return;
										}
										setGraphState({
											...graphState,
											max: newValue!,
										});
									}}
								/>
							</InputWithLabel>
							<InputWithLabel
								label={
									<span className="text-sm">
										Min residual value
									</span>
								}
								htmlFor="objective_min_residual_vaue"
								labelClassName="w-64 mb-2 md:mb-0"
							>
								<TextInput
									placeholder="..."
									id="objective_min_residual_value"
									type="number"
									min="-50"
									max="100"
									step="1"
									className="w-full md:w-24 lg:w-28"
									value={inputValues.min}
									onChange={(e: any) => {
										const newValue = toNumber(
											e.target.value,
										);
										setInputValues({
											...inputValues,
											min: e.target.value,
										});
										// new value must be in range and not higher than max
										// we don't validate null
										if (
											newValue !== null &&
											(!limits.min.validate(newValue) ||
												newValue > graphState.max)
										) {
											return;
										}
										setGraphState({
											...graphState,
											min: newValue!,
										});
									}}
								/>
							</InputWithLabel>
							<InputWithLabel
								label={
									<span className="text-sm">
										Sell through target
									</span>
								}
								htmlFor="objective_sell_through_target"
								labelClassName="w-64 mb-2 md:mb-0"
							>
								<TextInput
									placeholder="..."
									id="objective_sell_through_target"
									type="number"
									min="0"
									max="100"
									step="1"
									className="w-full md:w-24 lg:w-28"
									value={toInputValue(graphState.sell)}
									onChange={(e: any) => {
										const newValue = toNumber(
											e.target.value,
										);
										setInputValues({
											...inputValues,
											sell: e.target.value,
										});
										// new value must be in range
										if (
											newValue !== null &&
											!limits.sell.validate(newValue)
										) {
											return;
										}
										setGraphState({
											...graphState,
											sell: newValue!,
										});
									}}
								/>
							</InputWithLabel>
						</div>
						<ResidualValueGraph
							maxResidualValue={graphState.max}
							sellThroughTarget={graphState.sell ?? 100}
							minResidualValue={graphState.min ?? graphState.max}
							extraDataPoints={[]}
							height={250}
							width={400}
						/>
					</div>
				</div>

				<div className="flex justify-between p-3">
					<Button
						variant="ghostFill"
						color="neutral"
						className="font-medium"
						onClick={close}
					>
						Close
					</Button>
					<Button
						className="flex flex-row items-center gap-3 whitespace-nowrap text-ca-silver"
						type="submit"
						variant="primary"
						onClick={save}
					>
						<CheckmarkIcon />
					</Button>
					<Tooltip content="Close">
						<Button
							squarePadding
							variant="ghostFill"
							onClick={close}
							className="absolute right-0 top-0 z-20 m-2"
						>
							<CrossIcon className="w-3 text-ca-gray-400" />
						</Button>
					</Tooltip>
				</div>
			</PopoverContent>
		</Popover>
	);
};
