import { LIST_CURRENT_SEASON, LIST_PHASES } from 'api/seasons';
import { useQueryClient } from 'react-query';

import {
	Button,
	CornerPinger,
	OpenOverlayIcon,
	Tooltip,
	useModal,
	WarningIcon,
} from 'crunch-components';

import useChannelQueries from 'hooks/channels/useChannelQueries';
import useChannelStore from 'hooks/channels/useChannelStore';
import constructChannelQueryKey from 'utils/channelUtils';
import { SeasonPhasesType, SeasonType } from '../types/seasons';
import {
	EMPTY_PHASES,
	SEASON_CURRENT_QUERY_KEY,
	SEASON_PHASES_QUERY_KEY,
	transformPhases,
} from './queries';
import SeasonSettingsModal from './SeasonSettingsModal';
import { getPhasesWarnings, getSeasonWarnings } from './strategyWarnings';
import { transformSeason } from './utils';

const SeasonSettings = () => {
	const activeChannel = useChannelStore((s) => s.activeChannel);
	const { open } = useModal();

	const [phasesResp, seasonResp] = useChannelQueries({
		queries: [
			{
				queryKey: SEASON_PHASES_QUERY_KEY,
				queryFn: LIST_PHASES,
				select: transformPhases,
			},
			{
				queryKey: SEASON_CURRENT_QUERY_KEY,
				queryFn: LIST_CURRENT_SEASON,
				select: transformSeason,
			},
		],
	});

	const queryClient = useQueryClient();
	const seasonPhasesQueryKey = constructChannelQueryKey(
		activeChannel,
		SEASON_PHASES_QUERY_KEY,
	);
	const currentSeasonQueryKey = constructChannelQueryKey(
		activeChannel,
		SEASON_CURRENT_QUERY_KEY,
	);

	const {
		isLoading: isPhasesLoading,
		data: phasesData,
		refetch: refetchPhases,
	} = phasesResp;
	const {
		isLoading: isSeasonLoading,
		data: seasonData,
		refetch: refetchSeason,
	} = seasonResp;

	const phases = (phasesData as SeasonPhasesType) ?? EMPTY_PHASES;
	const season = (seasonData as SeasonType) ?? {};

	const onSuccess = async () => {
		await Promise.all([refetchPhases(), refetchSeason()]);
	};

	const onMutate = async () => {
		await queryClient.cancelQueries([
			seasonPhasesQueryKey,
			currentSeasonQueryKey,
		]);
	};

	const seasonSettingsClick = () =>
		open({
			modalNode: (
				<SeasonSettingsModal
					phases={phases}
					season={season}
					isLoading={isPhasesLoading || isSeasonLoading}
					onMutate={onMutate}
					onSuccess={onSuccess}
				/>
			),
			sizeClass: 'w-4/5 max-w-5xl',
		});

	const phasesWarnings = getPhasesWarnings({
		phases,
		expectEndOfSeason: false,
	});
	const seasonWarnings = getSeasonWarnings(season);

	return (
		<CornerPinger
			hidden={
				!phasesWarnings.warnings.noScheduledPhases &&
				seasonWarnings.count === 0
			}
		>
			<Tooltip
				content={
					seasonWarnings.warnings.seasonEnded ??
					phasesWarnings.warnings.noScheduledPhases ??
					undefined
				}
				placement="left"
			>
				<Button
					size="page-cta"
					className="flex flex-row items-center justify-center gap-2 whitespace-nowrap font-normal"
					onClick={seasonSettingsClick}
				>
					{(!!phasesWarnings.warnings.noScheduledPhases ||
						seasonWarnings.count > 0) && (
						<WarningIcon className="h-3.5 w-auto" />
					)}
					{!phasesWarnings.warnings.noScheduledPhases &&
						seasonWarnings.count === 0 && (
							<OpenOverlayIcon className="h-3.5 w-auto" />
						)}
					Season settings
				</Button>
			</Tooltip>
		</CornerPinger>
	);
};

export default SeasonSettings;
