import HeaderNav from 'components/HeaderNav/HeaderNav';
import Title from 'components/Title/Title';
import { Helmet } from 'react-helmet-async';
import ChannelSelector from '../../components/ChannelSelector/ChannelSelector';
import SeasonSettings from './v2/SeasonSettings';

const NAV_ITEMS = [
	{
		id: 'strategies',
		label: 'Strategies',
		to: '/strategy/strategies',
	},
	{
		id: 'objective-groups',
		label: 'Objectives',
		to: '/strategy/objectives',
	},
	{
		id: 'business-rules',
		label: 'Business rules',
		to: '/strategy/business-rules',
	},
];

export default function StrategyHeader() {
	return (
		<header>
			<Helmet title="Markmi | Strategy" />
			<div className="relative flex justify-between">
				<Title size="h1-like" font="serif" color="forest" as="h1">
					Strategy
				</Title>
				<ChannelSelector />
			</div>
			<div className="relative mt-6 flex flex-row items-center gap-4">
				<HeaderNav items={NAV_ITEMS} />
				<SeasonSettings />
			</div>
		</header>
	);
}
