/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */

import {
	getMondayThatWeek,
	isWeekAfter,
	today as getToday,
} from 'crunch-utils';
import { scaleLinear } from 'd3';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import tailwindColors from 'tailwindcss/colors';
import calculateAuc from '../components/calculateAucValue';
import {
	PhasesDataType,
	PhaseType,
	SeasonDataType,
	SeasonType,
} from '../types/seasons';
import {
	GraphData,
	PresetSliderValue,
	ScenariosDataType,
	ScenariosForObjectiveType,
	StrategyObjectivesDataType,
	StrategyStatusDataType,
} from '../types/strategies';

import { GridState } from './grid';
import { RuleBasicDataType } from './queries';

dayjs.extend(isoWeek);

export type ObjectiveFormData = {
	id: StrategyObjectivesDataType[number]['id'];
	intensity: number;
};

export const transformRulesForSubmit = ({
	gridState,
	rules,
	phases,
}: {
	gridState: GridState;
	rules: RuleBasicDataType[];
	phases: PhasesDataType;
}) => {
	return rules.map((rule) => {
		return {
			id: rule.id,
			phase_assignments: phases.map((phase) => {
				return {
					id: phase.id,
					active: gridState[rule.id][phase.id],
				};
			}),
		};
	});
};

export const END_OF_SEASON_PHASE_CONSTANTS: Omit<
	PhaseType,
	'startDate' | 'start_date'
> = {
	completed: true,
	type: 'endseason',
	id: 'end_of_season',
	name: 'End of season',
	hasInput: false,
} as const;

export const constructEndSeasonFakePhase = (endDate: SeasonType['endDate']) => {
	return {
		...END_OF_SEASON_PHASE_CONSTANTS,
		startDate: endDate,
		start_date: endDate.format('YYYY-MM-DD'),
	};
};

export const transformSeason = (data: SeasonDataType) => {
	const today = getToday();
	const fixedStartDate =
		window._ENV_.REACT_APP_FIXED_T_OF_INTEREST === false
			? today
			: dayjs(window._ENV_.REACT_APP_FIXED_T_OF_INTEREST.toString());

	const endDate = dayjs(data.end_date);

	return {
		...data,
		endDate,
		isInPast: !isWeekAfter({ date: endDate, endDate: fixedStartDate }),
	};
};

export const transformObjectivesForSubmit = ({
	allScenarios,
	formObjectives,
}: {
	formObjectives: ObjectiveFormData[];
	allScenarios: ScenariosForObjectiveType;
}) => {
	return formObjectives.map((item) => {
		// TODO if we fail here we will fail at API
		// need some error handling
		const scenarios = allScenarios[item.id];
		const found = scenarios.find(
			(scenario) => scenario.intensity_level === item.intensity,
		)!;

		return {
			id: item.id,
			strategy_objective: {
				...found,
				scenario_name: found.name,
				name: undefined,
			},
		};
	});
};

// TODO made for tests
export const validateIntensity = (n: number) => {
	const rounded = Math.round(n / 10) * 10;
	return Math.max(0, Math.min(100, rounded));
};

export const findClosestScenarioIndex = ({
	scenarios,
	targetAuc,
}: {
	scenarios: ScenariosDataType;
	targetAuc: number;
}) => {
	let foundIdx = 0;
	let lastClosestAuc = 0;

	for (let i = 1; i < scenarios.length; i++) {
		const current = scenarios[i];
		const currentAuc = calculateAuc(
			current.max_residual_value,
			current.min_residual_value!,
			current.sell_through_target!,
		);
		if (
			Math.abs(targetAuc - currentAuc) < Math.abs(targetAuc - lastClosestAuc)
		) {
			foundIdx = i;
			lastClosestAuc = currentAuc;
		}
	}

	return foundIdx;
};

const RESIDUAL_VALUE_COLOR_SCALE = scaleLinear(
	[0, 0.5, 1],
	[
		tailwindColors.green['500'],
		tailwindColors.yellow['500'],
		tailwindColors.red['500'],
	],
);
/**
 * args:
 * `percentage` between 0 and 1
 */
export const getScenarioHexColor = (percentage: number) => {
	return RESIDUAL_VALUE_COLOR_SCALE(percentage);
};

export const findMatchedScenarioIndex = ({
	scenarios,
	sliderValue,
}: {
	scenarios: ScenariosDataType;
	sliderValue: PresetSliderValue;
}) => {
	// check if any scenarios matches already
	for (let idx = 0; idx < scenarios.length; idx++) {
		const scenario = scenarios[idx];
		if (
			scenario.max_residual_value === sliderValue.max_residual_value &&
			scenario.min_residual_value === sliderValue.min_residual_value &&
			scenario.sell_through_target === sliderValue.sell_through_target
		) {
			return idx;
		}
	}

	return findClosestScenarioIndex({
		scenarios,
		targetAuc: calculateAuc(
			sliderValue.max_residual_value,
			sliderValue.min_residual_value!,
			sliderValue.sell_through_target!,
		),
	});
};

export const insertCustomScenario = ({
	scenarios,
	customData,
}: {
	scenarios: ScenariosDataType;
	customData: GraphData;
}) => {
	const auc = calculateAuc(customData.max, customData.min, customData.sell);
	const newScenarios = scenarios.map((obj) => ({ ...obj }));
	const idx = findClosestScenarioIndex({
		scenarios: newScenarios,
		targetAuc: auc,
	});
	newScenarios[idx] = {
		...newScenarios[idx],
		max_residual_value: customData.max,
		min_residual_value: customData.min,
		sell_through_target: customData.sell,
	};
	// returns intensity level of the scenario replaced
	return [newScenarios, newScenarios[idx].intensity_level] as const;
};

// replaces data in a matching scenario with new values
export const replaceExistingScenario = ({
	scenarios,
	existingScenario,
}: {
	scenarios: ScenariosDataType;
	existingScenario: StrategyObjectivesDataType[number];
}) => {
	const customData = {
		max: existingScenario.strategy_objective.max_residual_value,
		min: existingScenario.strategy_objective.min_residual_value,
		sell: existingScenario.strategy_objective.sell_through_target,
	} as any; // TODO fix

	// check if any scenarios match already
	for (const scenario of scenarios) {
		if (
			scenario.max_residual_value === customData.max &&
			scenario.min_residual_value === customData.min &&
			scenario.sell_through_target === customData.sell
		) {
			return [scenarios, scenario.intensity_level] as const;
		}
	}

	const auc = calculateAuc(customData.max, customData.min, customData.sell);

	const newScenarios = scenarios.map((obj) => ({ ...obj }));
	const idx = findClosestScenarioIndex({
		scenarios: newScenarios,
		targetAuc: auc,
	});
	newScenarios[idx] = {
		...newScenarios[idx],
		max_residual_value: customData.max,
		min_residual_value: customData.min,
		sell_through_target: customData.sell,
	};

	// returns intensity level of the scenario replaced
	return [newScenarios, newScenarios[idx].intensity_level] as const;
};

// TODO: split to a dedicated copy/i18n file?
export function mapOutdatedReasonToCopy(
	reason: StrategyStatusDataType['outdated_reason'],
	strategy_name: string | null = null,
): string | null {
	if (reason == null) {
		return null;
	}

	if (reason === 'SEASON_SETTINGS') {
		return `The latest run ${
			strategy_name === null ? '' : `for strategy '${strategy_name}'`
		} is out of date with your season settings. Start a new run to get updated data.`;
	}

	if (reason === 'PHASE_SETTINGS') {
		return `The latest run ${
			strategy_name === null ? '' : `for strategy '${strategy_name}'`
		} is out of date with your phase settings. Start a new run to get updated data.`;
	}

	if (reason === 'T_OF_INTEREST') {
		return `The latest run ${
			strategy_name === null ? '' : `for strategy '${strategy_name}'`
		} is out of date with your current data. Other strategies have been run with more info. Start a new run to refresh.`;
	}

	return `The latest run ${
		strategy_name === null ? '' : `for strategy '${strategy_name}'`
	} is out of date with your settings. Start a new run to get updated data.`;
}

export const getNewPhaseWeek = ({ phases }: { phases: PhaseType[] }) => {
	// if no phases - next week from today
	// if phases - one week after that phase
	const existingDates = phases
		.filter((item) => item.type !== 'endseason')
		.map((item) => item.startDate);
	let nextPhaseWeek =
		existingDates.length === 0
			? getMondayThatWeek(getToday())
			: existingDates[existingDates.length - 1].add(1, 'week');

	nextPhaseWeek = nextPhaseWeek.startOf('isoWeek');

	return nextPhaseWeek;
};
