import ChannelSelector from 'components/ChannelSelector/ChannelSelector';
import Title from 'components/Title/Title';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

function GlobalHeader() {
	return (
		<div className="relative flex justify-between">
			<Helmet title="Markmi | Global" />
			<Title size="h1-like" font="serif" color="forest" as="h1">
				Global
			</Title>
			<ChannelSelector />
		</div>
	);
}

export default function GlobalLayout() {
	return (
		<section>
			<GlobalHeader />
			<Outlet />
		</section>
	);
}
