import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, } from 'react';
const Button = forwardRef(({ variant = 'primary', type = 'button', squarePadding = false, size = 'regular', disabled, form, className, children, onClick, ...rest }, ref) => {
    return (_jsx("button", { ref: ref, className: clsx('transition-colors leading-none focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple', disabled
            ? clsx('cursor-not-allowed text-black opacity-40', variant !== 'link' && 'bg-ca-gray')
            : clsx(
            // TODO: fix!
            // Has been manually set to Markmi's color palette for now, this will likely break Vulcan
            variant === 'primary' &&
                'font-bold text-white bg-mi-velvet-lilac hover:brightness-[110%] active:brightness-[110%] focus:border focus:border-mi-velvet-lilac-light', variant === 'secondary' &&
                'font-medium text-white bg-ca-gray-400 focus-visible:bg-ca-gray-a hover:bg-ca-gray-a active:bg-ca-gray-a', variant === 'danger' &&
                'font-bold text-white bg-ca-red focus-visible:bg-ca-red-a hover:bg-ca-red-a active:bg-ca-red-a', variant === 'success' &&
                'font-bold text-white bg-ca-green focus-visible:bg-ca-green-a hover:bg-ca-green-a active:bg-ca-green-a', variant === 'warning' &&
                'font-bold text-white bg-ca-orange focus-visible:bg-ca-orange-a hover:bg-ca-orange-a active:bg-ca-orange-a', variant === 'link' && 'text-ca-purple hover:underline', variant === 'ghostFill' && 'bg-transparent hover:bg-ca-silver'), size === 'regular' &&
            variant !== 'unstyled' &&
            `rounded-lg text-sm ${squarePadding ? 'p-3' : 'py-3 px-4'}`, size === 'small' &&
            variant !== 'unstyled' &&
            `rounded text-xxs ${squarePadding ? 'p-2' : 'py-2 px-3'}`, size === 'page-cta' &&
            variant !== 'unstyled' &&
            'p-0 rounded text-xs w-[168px] h-[30px] shrink-0', className), type: type, disabled: disabled, onClick: onClick, form: form, ...rest, children: children }));
});
export default Button;
