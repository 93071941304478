import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
/**
 * A spinning half circle. Indicator for elements which are still waiting to be updated.
 */
const LoadingSpinner = (props) => {
    return props?.hidden && !props.antiVisualJumping ? (_jsx(_Fragment, {})) : (_jsx("div", { className: cn('inline-block rounded-full animate-[spin_0.6s_linear_infinite] border-l-ca-purple border-t-ca-purple border-r-transparent border-b-transparent transition-opacity', (props?.variant === 'sm' || props.variant === undefined) &&
            'mx-1 w-2 h-2 border-2', props?.variant === 'md' && 'mx-2 w-4 h-4 border-2', props?.variant === 'lg' && 'mx-2 w-6 h-6 border-2', props?.antiVisualJumping && props?.hidden ? 'opacity-0' : 'opacity-100', props.className) }));
};
export default LoadingSpinner;
