import StratosLogOutButton from 'components/StratosSidebar/StratosLogOutButton';
import { Logo, Page, Text } from 'crunch-components';

// TODO: fix scrolling
// TODO: fix email when moved
export default function NoAccess() {
	return (
		<Page>
			<Logo className="h-full w-12" />
			<div className="flex min-h-screen flex-col items-center justify-center gap-6">
				<img
					alt="No access, damn"
					src={process.env.PUBLIC_URL + '/facepalm_tshirt.png'}
					width="480"
					height="360"
					className="mb-10 rounded-lg"
				/>
				<Text>
					<span>
						You have not been assigned access to any channels.{' '}
					</span>
					<a href="mailto:support@markmi.ai" className="underline">
						Please contact support@markmi.ai.
					</a>
				</Text>
				<Text>
					<span>
						You may need to log out for changes to take effect.{' '}
					</span>
				</Text>
				<StratosLogOutButton />
			</div>
		</Page>
	);
}
