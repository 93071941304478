import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from '../../utils/cn';
import PropTypes from 'prop-types';
const Conditional = ({ label, children, classNameLeftBorder, classNameLabel }) => (_jsxs("div", { className: cn('relative border-l-2 border-ca-gray', classNameLeftBorder), children: [_jsx("span", { className: cn("absolute -left-px top-0 py-2 px-3 rounded rounded-l-none font-medium leading-none text-xs bg-ca-gray text-white", classNameLabel), children: label }), _jsx("div", { className: "pl-5 pt-9", children: children })] }));
Conditional.propTypes = {
    label: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    classNameLeftBorder: PropTypes.string,
    classNameLabel: PropTypes.string,
};
Conditional.defaultProps = {
    classNameLeftBorder: '',
    classNameLabel: '',
};
export default Conditional;
