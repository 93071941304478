import { cn } from 'crunch-components';
import { FunctionComponent } from 'react';
import { EElementState } from '../types/MarkdownTransferTypes';

interface Props {
	state: EElementState;
}

const MarkdownTransferBrace: FunctionComponent<Props> = (props) => {
	return (
		<div
			className={cn(
				props.state === 'disabled' ? 'opacity-30' : 'opacity-100',
				props.state === 'focused' ? 'border-mi-velvet-lilac' : '',
				'mt-4 flex flex-col items-center transition-opacity duration-500',
			)}
		>
			<div
				className={cn(
					props.state === 'focused'
						? 'border-mi-velvet-lilac'
						: 'border-mi-neutral',
					'h-4 w-full rounded-b border border-t-0',
				)}
			/>
			<div
				className={cn(
					props.state === 'focused'
						? 'border-mi-velvet-lilac'
						: 'border-mi-neutral',
					'h-4 border-l',
				)}
			/>
		</div>
	);
};

export default MarkdownTransferBrace;
