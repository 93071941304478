import { ReactNode } from 'react';

import {
	Badge,
	InfoIcon,
	MissingValue,
	Text,
	Tooltip,
} from 'crunch-components';
import { components } from '../../../../../../types/backend-api';

type GlobalProps = {
	type: 'Global';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'global_action'
	>;
};

type AverageProps = {
	type: 'Custom_average';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'custom_average_action'
	>;
};

type DistributionProps = {
	type: 'Custom_distribution';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'custom_distribution_action'
	>;
};

type FixedProps = {
	type: 'Custom_fixed';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'custom_fixed_action'
	>;
};

type MaxIncreaseProps = {
	type: 'Custom_max_increase';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'custom_max_increase_action'
	>;
};

type MinChangeProps = {
	type: 'Custom_min_change';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'custom_min_change_action'
	>;
};

type PossibleProps = {
	type: 'Custom_possible';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'custom_possible_action'
	>;
};

type MinMaxProps = {
	type: 'Custom_minmax';
	settings: Pick<
		components['schemas']['BusinessRuleAPIOutput'],
		'custom_minmax_action'
	>;
};

// Want to make this a discriminated union so that if type is 'Custom_minmax` that settings need to be of shape 'components['schemas']['BusinessRuleAPIOutput']['custom_minmax_action'] but I don't know hte correct syntax for this
type BusinessRuleSettingsBadgeProps = (
	| MinMaxProps
	| GlobalProps
	| PossibleProps
	| FixedProps
	| AverageProps
	| MaxIncreaseProps
	| DistributionProps
	| MinChangeProps
	| { type: undefined }
) & { key?: string };
const SecondaryText = (props: { children?: ReactNode; className?: string }) => {
	return props.children ? (
		<Text className={props.className ?? ''} type="secondary">
			{props.children}
		</Text>
	) : null;
};

const BusinessRuleSettingsBadge = (props: BusinessRuleSettingsBadgeProps) => {
	switch (props.type) {
		case 'Custom_average':
			return (
				<div className="flex gap-2" key={props.key}>
					<Badge variant="colorFuchsia">Average discount</Badge>
					{props.settings.custom_average_action?.average_discount ? (
						<SecondaryText>
							of{' '}
							{(
								props.settings.custom_average_action
									.average_discount * 100
							).toFixed(0)}
							%
						</SecondaryText>
					) : (
						<MissingValue />
					)}
				</div>
			);
		case 'Custom_fixed':
			return (
				<div className="flex gap-2" key={props.key}>
					<Badge variant="colorAppelblauwzeegroen">
						Fixed discount
					</Badge>
					{props.settings.custom_fixed_action?.fixed_discount !=
					null ? (
						<SecondaryText>
							of{' '}
							{(
								props.settings.custom_fixed_action
									.fixed_discount * 100
							).toFixed(0)}
							%
						</SecondaryText>
					) : (
						<MissingValue />
					)}
				</div>
			);
		case 'Custom_possible': {
			if (
				props.settings?.custom_possible_action?.markdowns === undefined
			) {
				return <MissingValue tooltip="Markdowns data missing" />;
			}
			const maxShown = 4;
			const plural =
				props.settings?.custom_possible_action.markdowns.length >= 2;
			const markdownsString =
				props.settings.custom_possible_action.markdowns
					.map((markdown, index, array) => {
						const isBeforeNextToLast = index < array.length - 2;
						const isNextToLast = index === array.length - 2;
						return `${(markdown * 100).toFixed(0)}%${
							isBeforeNextToLast ? ', ' : ''
						}${isNextToLast ? ' and ' : ''}`;
					})
					.join('');

			return (
				<div className="flex items-center gap-2" key={props.key}>
					<Badge className="mr-2" variant="colorPurple">
						Possible discount{plural && 's'}
					</Badge>{' '}
					{props.settings.custom_possible_action.markdowns.length >
					maxShown ? (
						<Tooltip content={markdownsString}>
							<div>
								<SecondaryText className="flex items-center">
									{
										props.settings.custom_possible_action
											.markdowns.length
									}{' '}
									values
									<InfoIcon className="ml-1 mt-0.5 h-4 text-ca-gray" />
								</SecondaryText>
							</div>
						</Tooltip>
					) : (
						<SecondaryText>
							{plural ? 'are' : 'is'} {markdownsString}
						</SecondaryText>
					)}
				</div>
			);
		}
		case 'Custom_max_increase':
			return (
				<div className="flex gap-2" key={props.key}>
					<Badge variant="colorYellow">Max. increase</Badge>
					{props.settings.custom_max_increase_action
						?.max_increase_discount ? (
						<SecondaryText>
							{'of '}
							{(
								props.settings.custom_max_increase_action
									.max_increase_discount * 100
							).toFixed(0)}
							%
						</SecondaryText>
					) : (
						<MissingValue />
					)}
				</div>
			);

		case 'Custom_min_change':
			return (
				<div className="flex gap-2" key={props.key}>
					<Badge variant="colorPink">Min. change</Badge>
					{props.settings.custom_min_change_action
						?.min_change_discount ? (
						<SecondaryText>
							{'of '}
							{(
								props.settings.custom_min_change_action
									.min_change_discount * 100
							).toFixed(0)}
							%
						</SecondaryText>
					) : (
						<MissingValue />
					)}
				</div>
			);
		case 'Custom_minmax': {
			const max =
				props.settings?.custom_minmax_action?.max_discount ==
				null ? undefined : (
					<div className="flex gap-2" key="max">
						<Badge variant="colorIndigo">Max. discount</Badge>
						<SecondaryText>
							of{' '}
							{(
								props.settings.custom_minmax_action
									.max_discount * 100
							).toFixed(0)}
							%
						</SecondaryText>
					</div>
				);

			const min =
				props.settings?.custom_minmax_action?.min_discount ==
				null ? undefined : (
					<div className="flex gap-2" key="min">
						<Badge variant="colorIndigo">Min. discount</Badge>
						<SecondaryText>
							{' '}
							of{' '}
							{(
								props.settings.custom_minmax_action
									.min_discount * 100
							).toFixed(0)}
							%
						</SecondaryText>
					</div>
				);

			return (
				<div className="flex gap-2" key={props.key}>
					{!min && !max && <MissingValue />}
					{max && max}
					{min && min}
				</div>
			);
		}

		case 'Custom_distribution': {
			const settings = props.settings.custom_distribution_action;

			if (!settings) {
				return (
					<MissingValue tooltip="Settings for type `Distribution` is not defined." />
				);
			}
			return (
				<div className="flex gap-2" key={props.key}>
					<Badge variant="colorLime">Distribution</Badge>
					<SecondaryText>
						{settings.limiter && settings?.limiter === 'max'
							? 'Max. '
							: 'Min.  '}
						{(settings.distribution * 100).toFixed(0)}
						{'% of '}
						{settings.type}
						{' should be discounted at '}
						{(settings.discount * 100).toFixed(0)}%
					</SecondaryText>
				</div>
			);
		}
		case 'Global': {
			return (
				<div key={props.key}>
					<Badge
						variant="colorIce"
						className="overflow-ellipsis whitespace-nowrap"
					>
						{props.settings.global_action?.description}
					</Badge>
				</div>
			);
		}
		default:
			return (
				<MissingValue
					tooltip="Unrecognized business rule type."
					key="missingValue"
				/>
			);
	}
};

export default BusinessRuleSettingsBadge;
