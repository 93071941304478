import Title from "components/Title/Title";

const MarkdownTransferSelection = ({ children }) => {
	return (
		<section>
	
			{children}
		</section>
	);
};

export default MarkdownTransferSelection;
